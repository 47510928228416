
import { contractorsModule } from '@/modules/contractors/shared/state/module';
import { CostDataRequest } from '@/modules/costs/shared/requests/cost-data-request';
import { costsModule } from '@/modules/costs/shared/state/module';
import { costTypesModule, costCategoriesModule } from '@/modules/costs/shared/state/submodules';
import { invoicePaymentMethods } from '@/shared/config/invoices';
import { FormRules } from '@/shared/validation/form-rules';
import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ContractorsForm from '@/modules/contractors/components/form.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import FormComponent from '@/shared/components/layout/form/form-component';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { httpClient } from '@/shared/services';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
        DatePicker,
        TextContrast,
    },
})
export default class CostEditForm extends FormComponent<CostDataRequest> {
    public requestData: CostDataRequest = new CostDataRequest();

    get formTitle() {
        return 'Edytuj Koszt';
    }

    get categories() {
        return this.$store.state.costsState.categoriesState.data;
    }

    get loadingCategories() {
        return this.$store.state.costsState.categoriesState.loading;
    }

    get costTypes() {
        return this.$store.state.costsState.typesState.data;
    }

    get loadingCostTypes() {
        return this.$store.state.costsState.typesState.loading;
    }

    public actionsTypes = costsModule.actionsTypes;
    public formRules = new FormRules();
    public valid = false;
    public store = this.$store.state.costsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
    public paymentMethods = invoicePaymentMethods;

    public ContractorsForm = ContractorsForm;
    public contractorsModule = contractorsModule;
    public contractorsState = this.$store.state.contractorsState;
    public contractorsActionsTypes = contractorsModule.actionsTypes;
    public contractorsMutationsTypes = contractorsModule.mutationsTypes;

    public costTypesModule = costTypesModule;
    public costTypesActionsTypes = costTypesModule.actionsTypes;

    public costCategoriesModule = costCategoriesModule;
    public costCategoriesActionTypes = costCategoriesModule.actionsTypes;
    public categoriesValues = {};

    public numberErrors = [] as string[];
    public itemOrginalNumber = '';

    @Watch('itemData.contractor_id', { deep: true })
    public resetAutocompleteWithAddNewValidation() {
        ((this.$refs.autoCompleteWithAddNew as Vue).$children[0] as any).resetValidation();
    }

    @Watch('itemData.number')
    public validateNumber(v: string) {
        this.numberErrors.pop();
        if (v === this.itemOrginalNumber) {
            return;
        }
        httpClient.post('/api/v1/costs/validate-number', { number: v }).catch((err: any) => {
            if (err.errors.number[0] === 'Wartość pola Numer została już użyta.') {
                this.numberErrors.push('Numer jest już zajęty');
            }
        });
    }

    public initForm() {
        this.form = this.$refs.editCostForm;
        this.categoriesValues = this.itemData.categories;
         }

    public created() {
        this.itemOrginalNumber = this.itemData.number;
        this.$store.dispatch(this.costTypesActionsTypes.FETCH_DATA, { simple: false });
        this.$store.dispatch(this.costCategoriesActionTypes.FETCH_DATA, { simple: false });
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public async submitForm(e: any, next?: boolean) {
        this.updateCategories(this.categoriesValues);
        callSuper(this, 'submitForm', e, next);
    }

    public updateCategories(event: any) {
        this.$set(this.itemData, 'categories', [...event.map((cat: any) => cat.id)]);
    }
}
