
import Vue from 'vue';
import Component from 'vue-class-component';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import SelectMediaList from '@/modules/media/components/select.vue';
import { costAttachmentsTypes } from '@/shared/config/costs';
import { PropSync, VModel, Watch } from 'vue-property-decorator';
import { FormRules } from '@/shared/validation/form-rules';
import { MediaFile } from '@/modules/media/shared/models/media-file';
import _ from 'lodash';
import { CostAttachment } from '@/modules/costs/shared/models/cost-attachment';

@Component({
    components: {
        MediaFileInput,
        SelectMediaList,
    },
})
export default class AttachmentsSelector extends Vue {
    @VModel({ type: Array }) public attachments!: any[];
    public mediaSelectDialog = false;
    public formRules = new FormRules();
    public valid = false;
    public costAttachmentsTypes = costAttachmentsTypes;

    get attachmentsWithTypes() {
        return this.attachments.map((attachment) => {
            return {
                ...attachment.media_file,
                type: this.getAttachmentType(attachment.type),
            };
        });
    }

    public itemSelected(selectedMedia: MediaFile[]) {
        const newAttachments = [];

        for (const file of selectedMedia) {
            const existingItem = this.attachments.find((attachment: any) => attachment.media_file_id === file.id);
            newAttachments.push(
                (existingItem || {
                    media_file_id: file.id,
                    media_file: file,
                    type: costAttachmentsTypes[0].id,
                }) as unknown as CostAttachment,
            );
        }

        this.attachments = newAttachments;
        this.mediaSelectDialog = false;
    }

    private getAttachmentType(attachmentItemId: string) {
        return this.costAttachmentsTypes.find((type) => type.id === attachmentItemId);
    }
}
