
import { documentTemplatesListHeaders } from '@/modules/documents/shared/config';
import { DocumentTemplate } from '@/modules/documents/shared/models/document-template';
import { DocumentTemplateDataRequest } from '@/modules/documents/shared/requests/document-template-data-request';
import { documentTemplatesModule } from '@/modules/documents/shared/state/submodules';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DocumentTemplateDetails from '../details/details.vue';
import DocumentTemplateForm from '../form.vue';
import DocumentTile from '@/modules/documents/components/list/tile.vue';

@Component({
    components: {
        ItemsTable,
        DocumentTemplateDetails,
        DocumentTemplateForm,
        DocumentTile,
    },
})
export default class TileView extends ListComponent<DocumentTemplate, DocumentTemplateDataRequest> {
    @Prop({ type: Boolean, default: true }) public isEditingRestricted!: boolean;
    public headers: object[] = documentTemplatesListHeaders;
    public actionsTypes = documentTemplatesModule.actionsTypes;
    public mutationTypes = documentTemplatesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.documentsState.templatesState;
    public props: ModuleProps = documentTemplatesModule.moduleProps;

    public mounted() {
        this.fetchData();
        // console.log(window.screen.width);
    }
}
