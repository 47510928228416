
import { rentCostExamplePositionsListHeaders } from '@/modules/rent-costs/shared/config/data-settings';
import { RentCostExamplePosition } from '@/modules/rent-costs/shared/models/rent-cost-example-position';
import store from '@/store';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { rentCostExamplePositionsModule } from '../../shared/state/submodules';

@Component({
})
export default class PositionTemplateAutocomplete extends Vue {
    @Prop([String, Object]) public value!: any;
    @Prop([Object, Array]) public rules!: any;
    @Prop(Object) public item!: any;
    @Prop(Boolean) public disabled!: boolean;
    public menu = false;
    public headers = rentCostExamplePositionsListHeaders;
    public templatePositionts = [] as RentCostExamplePosition[];
    public store = this.$store.state.rentcostsState.examplePositionsState;
    public actionTypes = rentCostExamplePositionsModule.actionsTypes;
    public fetchAction = this.actionTypes.FETCH_DATA;
    public timer: any = 0;
    public autocompletion = '';
    public showNameAutocompletion = false;

    @Watch('menu')
    public onMenuChange(val: boolean) {
        if (val && this.templatePositionts.length === 0) {
            this.fetchData(this.$props.value);
        }
    }

    public isEqualToItem(position: any) {
        let isEqual = true;
        for (const [key, value] of Object.entries(position)) {
            if (key === 'index' || key === 'id') {
                continue;
            }
            if (this.$props.item[key] !== value) {
                isEqual = false;
            }
        }
        return isEqual;
    }

    get loading() {
        return this.store.loading;
    }

    public fetchData(search: string = '') {
        this.$store.dispatch(this.fetchAction, { filters: { search } }).then((res) => {
            this.templatePositionts = [];
            this.$nextTick(() => {
                this.templatePositionts = res.data;
            });
        });
    }

    get name() {
        if (!this.$props.value) {
            return '';
        } else if (typeof this.$props.value === 'string') {
            return this.$props.value;
        } else {
            return this.$props.value.name;
        }
    }
    set name(val) {
        this.$emit('input', val);
    }

    public onChange(position: RentCostExamplePosition) {
        this.menu = false;
        this.$emit('clearAutocompletion', position);
        this.$emit('change', position);
    }
    public onClickOutside(e: any) {
        this.menu = false;
    }

    public onKeypress(e: any) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.fetchData(e.target.value);
        }, 400);
    }
    public showAutocompletion(position: RentCostExamplePosition) {
        this.showNameAutocompletion = false;
        setTimeout(() => {
            this.showNameAutocompletion = true;
        }, 100);
        this.autocompletion = position.name;
        this.$emit('showAutocompletion', position);
    }
    public clearAutocompletion(position: RentCostExamplePosition) {
        this.showNameAutocompletion = false;
        this.$emit('clearAutocompletion', position);
    }
}
