
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { rentCostTypesListHeaders } from '@/modules/rent-costs/shared/config';
import { rentCostTypesModule } from '@/modules/rent-costs/shared/state/submodules';
import RentCostTypeForm from '@/modules/rent-costs/components/settings/types/form.vue';
import RentCostType from '@/modules/rent-costs/shared/models/rent-cost-type';
import { RentCostTypeDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-type-data-request';

@Component({
    components: {
        RentCostTypeForm,
        ItemsTable,
    },
})
export default class RentCostTypesList extends ListComponent<RentCostType,
    RentCostTypeDataRequest> {
    public headers: object[] = rentCostTypesListHeaders;
    public actionsTypes = rentCostTypesModule.actionsTypes;
    public mutationTypes = rentCostTypesModule.mutationsTypes;
    public props: ModuleProps = rentCostTypesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.rentcostsState.typesState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    public closeForm() {
        this.editedItem = {} as RentCostTypeDataRequest;
        this.formDialog = false;
    }
}
