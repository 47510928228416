
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    documentTagsListHeaders,
} from '@/modules/documents/shared/config';
import { documentTagsModule } from '@/modules/documents/shared/state/submodules';
import DocumentTagForm from '@/modules/documents/components/settings/tags/form.vue';
import {DocumentTag} from '@/modules/documents/shared/models/document-tag';
import { DocumentTagDataRequest } from '@/modules/documents/shared/requests/document-tag-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        DocumentTagForm,
        ItemsTable,
    },
})
export default class DocumentTagsList extends ListComponent< DocumentTag, DocumentTagDataRequest> {
    public headers: object[] = documentTagsListHeaders;
    public actionsTypes = documentTagsModule.actionsTypes;
    public mutationTypes = documentTagsModule.mutationsTypes;
    public props: ModuleProps = documentTagsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.documentsState.tagsState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }


    public closeForm() {
        this.editedItem = {} as DocumentTagDataRequest;
        callSuper(this, 'closeForm', );
    }
}
