
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { costTypesListHeaders } from '@/modules/costs/shared/config';
import { costTypesModule } from '@/modules/costs/shared/state/submodules';
import CostTypeForm from '@/modules/costs/components/settings/types/form.vue';
import CostType from '@/modules/costs/shared/models/cost-type';
import { CostTypeDataRequest } from '@/modules/costs/shared/requests/cost-type-data-request';

@Component({
    components: {
        CostTypeForm,
        ItemsTable,
    },
})
export default class CostTypesList extends ListComponent<CostType, CostTypeDataRequest> {
    public headers: object[] = costTypesListHeaders;
    public actionsTypes = costTypesModule.actionsTypes;
    public mutationTypes = costTypesModule.mutationsTypes;
    public props: ModuleProps = costTypesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.costsState.typesState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    public closeForm() {
        this.editedItem = {} as CostTypeDataRequest;
        this.formDialog = false;
    }
}
