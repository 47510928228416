
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { costsCategoriesListHeaders } from '@/modules/costs/shared/config';
import { costCategoriesModule } from '@/modules/costs/shared/state/submodules';
import CostCategoryForm from '@/modules/costs/components/settings/categories/form.vue';
import { CostCategory } from '@/modules/costs/shared/models/cost-category';
import { CostCategoryDataRequest } from '@/modules/costs/shared/requests/cost-category-data-request';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

@Component({
    components: {
        CostCategoryForm,
        ItemsTable,
        TextContrast,
    },
})
export default class CostsList extends ListComponent<CostCategory, CostCategoryDataRequest> {
    public headers: object[] = costsCategoriesListHeaders;
    public actionsTypes = costCategoriesModule.actionsTypes;
    public mutationTypes = costCategoriesModule.mutationsTypes;
    public props: ModuleProps = costCategoriesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.costsState.categoriesState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    // @Watch('formDialog')
    // public onFormDialog(val: any) {
    //     if (val) { (this.$refs.container as HTMLElement).style.maxHeight = '100vh';
    //     } else { (this.$refs.container as HTMLElement).style.maxHeight = this.containerDefaultMaxHeight; }
    // }

    // public itemSaved() {
    //     this.fetchData();
    //     this.closeForm();
    // }

    public closeForm() {
        this.editedItem = {} as CostCategoryDataRequest;
        this.formDialog = false;
    }
}
