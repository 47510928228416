
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {rentCostTypesModule} from '@/modules/rent-costs/shared/state/submodules';
import { RentCostTypeDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-type-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
    },
})
export default class RentCostTypeForm extends FormComponent<RentCostTypeDataRequest> {
    public actionsTypes = rentCostTypesModule.actionsTypes;
    public store: any = this.$store.state.costsState.typesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentCostTypeDataRequest();

    get formTitle(): string {
        return this.edit ? 'Edytuj rodzaj' : 'Dodaj rodzaj';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.createRentCostTypeForm;
         }
}
