
    import { Component, Watch } from 'vue-property-decorator';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import SubFormActions from './sub-form-actions.vue';
    import Vue from 'vue';
    import { FormRules } from '@/shared/validation/form-rules';
    import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
    import { costsModule } from '../../shared/state/module';
    import { CostDataRequest } from '../../shared/requests/cost-data-request';
    import { investmentsModule } from '@/modules/investments/shared/state/module';
    import CardPicker from '@/shared/components/layout/form/card-picker.vue';
    import TextContrast from '@/shared/components/elements/text-contrast.vue';
    import AttachmentsSelector from '@/modules/costs/components/form/helpers/attachments-selector.vue';
    import { CostAttachment } from '../../shared/models/cost-attachment';

    @Component({
        components: { FormActions, SubFormActions, CardPicker, AttachmentsSelector, TextContrast },
        props: {
            itemData: Object,
            referencePhoto: Object,
            step: Number,
        },
    })
    export default class CostFormEntryData extends Vue {
        public actionsTypes = costsModule.actionsTypes;
        public formRules = new FormRules();
        public valid = false;
        public itemData!: CostDataRequest;
        public store = this.$store.state.acceptancesState;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
        public errorMessages: any = {};
        public attachments: CostAttachment[] = [];

        public investmentsStore = this.$store.state.investmentsState;
        public investmentsActionsTypes = investmentsModule.actionsTypes;

        get canGoNext() {
            return !!(this.valid && this.itemData.investment_id);
        }

        get users() {
            return this.$store.state.adminState.users;
        }

        public created() {
            this.$store.dispatch(this.fetchUsersAction, {
                simple: true,
            });
        }

        public clearError(name: string) {
            if (this.errorMessages[name]) {
                delete this.errorMessages[name];
                if (this.errorMessages.length > 0) {
                    return;
                }
                return (this.store.error = '');
            }
        }

        @Watch('attachments', {deep: true})
        public onAttachmentsChange(val: CostAttachment[]) {
            this.$set(this.itemData, 'attachments', this.parseToRequestData(val));
        }

        private parseToRequestData(arr: CostAttachment[]) {
            return arr.map((el: CostAttachment) => {
                return {
                    media_file_id: el.media_file_id,
                    type: el.type,
                };
            });
        }
    }
