
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { documentsCategoriesListHeaders } from '@/modules/documents/shared/config';
import { documentCategoriesModule } from '@/modules/documents/shared/state/submodules';
import DocumentCategoryForm from '@/modules/documents/components/settings/categories/form.vue';
import { DocumentCategory } from '@/modules/documents/shared/models/document-category';
import { DocumentCategoryDataRequest } from '@/modules/documents/shared/requests/document-category-data-request';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        DocumentCategoryForm,
        ItemsTable,
        TextContrast,
    },
})
export default class DocumentsList extends ListComponent<DocumentCategory, DocumentCategoryDataRequest> {
    public headers: object[] = documentsCategoriesListHeaders;
    public actionsTypes = documentCategoriesModule.actionsTypes;
    public mutationTypes = documentCategoriesModule.mutationsTypes;
    public props: ModuleProps = documentCategoriesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.documentsState.categoriesState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    public onNext() {
        this.editedItem = { color: '#147BD1' } as DocumentCategoryDataRequest;
        this.formDialog = true;
    }

    // @Watch('formDialog')
    // public onFormDialog(val: any) {
    //     if (val) { (this.$refs.container as HTMLElement).style.maxHeight = '100vh';
    //     } else { (this.$refs.container as HTMLElement).style.maxHeight = this.containerDefaultMaxHeight; }
    // }

    // public itemSaved() {
    //     this.fetchData();
    //     this.closeForm();
    // }

    public closeForm() {
        this.editedItem = {} as DocumentCategoryDataRequest;
        callSuper(this, 'closeForm');
    }
}
