
import { Component, Inject, Watch, Ref, Prop } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import SubFormActions from './sub-form-actions.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';
import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
import { rentCostsModule } from '../../shared/state/module';
import { RentCostDataRequest } from '../../shared/requests/rent-cost-data-request';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { rentCostCategoriesModule, rentCostTypesModule } from '@/modules/rent-costs/shared/state/submodules';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { invoicePaymentMethods } from '@/shared/config/invoices';
import { departmentsModule } from '@/modules/departments/shared/state/module';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { httpClient } from '@/shared/services';
import { MetaItem } from '@/shared/types';
import { rentCostExampleIssuersModule} from '@/modules/rent-costs/shared/state/submodules';
@Component({
    components: {
        FormActions,
        SubFormActions,
        AutoCompleteWithAddNew,
        DatePicker,
        TextContrast },

})
export default class AcceptanceFormEntryData extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Ref() public scrollable!: HTMLElement;
    @Prop(Object) public itemData!: RentCostDataRequest;
    @Prop(Object) public referencePhoto!: any;
    @Prop(Number) public step!: number;
    public actionsTypes = rentCostsModule.actionsTypes;
    public formRules = new FormRules();
    public valid = false;
    public store = this.$store.state.acceptancesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
    public errorMessages: any = {};
    public paymentMethods = invoicePaymentMethods;
    public searchDepartments = '';
    public autocompleteBlur = true;
    public numberErrors = [] as string[];
    public searchCat = '';
    public searchType = '';
    public menu = false;
    public pickedName = '';
    public fetchIssuersAction = rentCostExampleIssuersModule.actionsTypes.FETCH_DATA;
    public inputTimer: any = {};
    public typeData: any = null;

    public typesSearch: any = null;
    public catsSearch: any = null;
    public rentCostTypesModule = rentCostTypesModule;
    public rentCostTypesActionsTypes = rentCostTypesModule.actionsTypes;
    public rentCostCategoriesModule = rentCostCategoriesModule;
    public rentCostCategoriesActionsTypes = rentCostCategoriesModule.actionsTypes;

    public departmentsModule = departmentsModule;
    public departmentsActionsTypes = departmentsModule.actionsTypes;
    public typeFilter = '';
    public meta = {} as MetaItem;


    @Watch('searchDepartments')
    public onSearchDepartments(val: string) {
        if (this.loadingDepartments || this.autocompleteBlur || val === '') {
            return;
        }
        setTimeout(() => {
            this.$store.dispatch(this.departmentsActionsTypes.FETCH_DATA, { simple: false, filters: { search: val } });
        }, 500);
    }

    get issuers() {
        return this.$store.state.rentcostsState.exampleIssuersState.data;
    }

    get departments() {
        return this.$store.state.departmentsState.data;
    }
    get loadingDepartments() {
        return this.$store.state.departmentsState.loading;
    }

    get categories() {
        return this.$store.state.rentcostsState.categoriesState.data;
    }

    get loadingCategories() {
        return this.$store.state.rentcostsState.categoriesState.loading;
    }

    get rentCostTypes() {
        return this.$store.state.rentcostsState.typesState.data;
    }

    get loadingRentCostTypes() {
        return this.$store.state.rentcostsState.typesState.loading;
    }

    get fetchTypes() {
        return { filters: { search: this.typeFilter } };
    }

    public created() {
        this.$store.dispatch(this.rentCostTypesActionsTypes.FETCH_DATA, { simple: false })
        .then((data: any) => {
            this.meta = data.data.meta;
        });
        this.$store.dispatch(this.rentCostCategoriesActionsTypes.FETCH_DATA, { simple: false });
        this.$store.dispatch(this.departmentsActionsTypes.FETCH_DATA, { simple: false });
        this.fetchIssuers();
    }

    public fetchIssuers() {
        this.$store.dispatch(this.fetchIssuersAction, {search: this.itemData.issuer_name})
            .then((data) => {
                this.$store.state.rentcostsState.exampleIssuersState.data = data.data;
            });
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public updateType(event: any) {
        if (event && event.id) {
            this.itemData.type_id = event.id;
        }
    }

    public updateTags(event: any) {
        if (typeof(event[event.length - 1]) === 'string') {
           event = event.slice(0, event.length - 2);
        }
        this.$set(this.itemData, 'categories', [...event.map((cat: any) => cat.id)]);
    }

    public searchCategory(event: any) {
        clearTimeout(this.catsSearch);
        this.catsSearch = setTimeout(() => {
            if (!event || event.length > 3) {
                this.$store.dispatch(this.rentCostCategoriesActionsTypes.FETCH_DATA, {
                    simple: false,
                    filters: {
                        search: event
                    }
                })
                .then((data) => {
                    this.$store.state.rentcostsState.categoriesState.data = data.data;
                });
            }
        }, 500);
    }

    public searchTypes(event: any) {
        this.typeFilter = event;
        clearTimeout(this.typesSearch);
        this.typesSearch = setTimeout(() => {
            if (!event || event.length > 3) {
                this.$store.dispatch(this.rentCostTypesActionsTypes.FETCH_DATA, {
                    simple: false,
                    filters: {
                        search: event
                    }
                })
                .then((data) => {
                    this.$store.state.rentcostsState.typesState.data = data.data;
                });
            }
        }, 500);
    }

    public issuerData(item: any) {
        this.itemData.issuer_name = item.name;
        this.itemData.issuer_address = item.address;
        this.itemData.nip = item.nip;
        this.pickedName = item.name;
    }

    @Watch('itemData.number')
    public validateNumber(v: string) {
        this.numberErrors.pop();
        httpClient.post('/api/v1/rentcosts/validate-number', { number: v }).catch((err: any) => {
            if (err.errors.number[0] === 'Wartość pola Numer została już użyta.') {
                this.numberErrors.push('Numer jest już zajęty');
            }
        });
    }

    @Watch('itemData.issuer_name')
    public onIssuerDataChange() {
        clearTimeout(this.inputTimer);
        if (this.itemData.issuer_name !== this.pickedName) {
            this.inputTimer = setTimeout(() => {
                this.fetchIssuers();
            }, 500);
        }
    }
}
