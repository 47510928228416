
import { Component, Prop } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import SubFormActions from './sub-form-actions.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';
import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
import { rentCostsModule } from '../../shared/state/module';
import { RentCostDataRequest } from '../../shared/requests/rent-cost-data-request';
import { rentsModule } from '@/modules/rents/shared/state/state';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import AttachmentsSelector from '@/modules/costs/components/form/helpers/attachments-selector.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';

@Component({
    components: {
        FormActions,
        SubFormActions,
        CardPicker,
        AttachmentsSelector,
        TextContrast,
        NameReduce
    },
})
export default class RentCostFormEntryData extends Vue {
    @Prop(Object) public itemData!: RentCostDataRequest;
    @Prop(Object) public referencePhoto!: any;
    @Prop(Number) public step!: number;
    public actionsTypes = rentCostsModule.actionsTypes;
    public formRules = new FormRules();
    public valid = false;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
    public errorMessages: any = {};

    public rentsStore = this.$store.state.rentsState;
    public rentsActionsTypes = rentsModule.actionsTypes;

    get canGoNext() {
        return !!(this.valid && this.itemData.rent_id);
    }

    get users() {
        return this.$store.state.adminState.users;
    }

    public created() {
        this.$store.dispatch(this.fetchUsersAction, {
            simple: true,
        });
        //
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages && this.errorMessages.length > 0) {
                return;
            }
            return (this.rentsStore.error = '');
        }
    }
}
