
import { costExamplePositionsListHeaders } from '@/modules/costs/shared/config/data-settings';
import { CostExamplePosition } from '@/modules/costs/shared/models/cost-example-position';
import store from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { costExamplePositionsModule } from '../../shared/state/submodules';

@Component({
    props: {
        value: [String, Object],
        rules: [Object, Array],
        item: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
})
export default class PositionTemplateAutocomplete extends Vue {
    public menu = false;
    public headers = costExamplePositionsListHeaders;
    public templatePositionts = [] as CostExamplePosition[];
    public store = this.$store.state.costsState.examplePositionsState;
    public actionTypes = costExamplePositionsModule.actionsTypes;
    public fetchAction = this.actionTypes.FETCH_DATA;
    public timer: any = 0;
    public autocompletion = '';
    public showNameAutocompletion = false;

    @Watch('menu')
    public onMenuChange(val: boolean) {
        if (val && this.templatePositionts.length === 0) {
            this.fetchData(this.$props.value);
        }
    }

    public isEqualToItem(position: any) {
        let isEqual = true;
        for (const [key, value] of Object.entries(position)) {
            if (key === 'index' || key === 'id') {
                continue;
            }
            if (this.$props.item[key] !== value) {
                isEqual = false;
            }
        }
        return isEqual;
    }

    get loading() {
        return this.store.loading;
    }

    public fetchData(search: string = '') {
        this.$store.dispatch(this.fetchAction, { filters: { search } }).then((res) => {
            this.templatePositionts = [];
            this.$nextTick(() => {
                this.templatePositionts = res.data;
            });
        });
    }

    get name() {
        if (!this.$props.value) {
            return '';
        } else if (typeof this.$props.value === 'string') {
            return this.$props.value;
        } else {
            return this.$props.value.name;
        }
    }
    set name(val) {
        this.$emit('input', val);
    }

    public onChange(position: CostExamplePosition) {
        this.menu = false;
        this.$emit('clearAutocompletion', position);
        this.$emit('change', position);
    }
    public onClickOutside(e: any) {
        this.menu = false;
    }

    public onKeypress(e: any) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.fetchData(e.target.value);
        }, 400);
    }
    public showAutocompletion(position: CostExamplePosition) {
        this.showNameAutocompletion = false;
        setTimeout(() => {
            this.showNameAutocompletion = true;
        }, 100);
        this.autocompletion = position.name;
        this.$emit('showAutocompletion', position);
    }
    public clearAutocompletion(position: CostExamplePosition) {
        this.showNameAutocompletion = false;
        this.$emit('clearAutocompletion', position);
    }
}
