
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

import DatePicker from '@/shared/components/elements/date-picker.vue';
import { logger } from '@/shared/services';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import { RentCostExamplePositionDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-example-position-data-request';
import { currencies } from '@/shared/config/prices';
import { actionsTypes as settingsActionsTypes } from '@/modules/settings/shared/state';
import { rentCostExamplePositionsModule } from '@/modules/rent-costs/shared/state/submodules';

@Component({
    components: {
        Wysiwyg,
        DatePicker,
        FormActions,
        AutoCompleteWithAddNew,
        ActionConfirmDialog,
        TextContrast,
        IconContrast,
    },
})
export default class ExamplePositionForm extends FormComponent<RentCostExamplePositionDataRequest> {
    public actionsTypes = rentCostExamplePositionsModule.actionsTypes;
    public store: any = this.$store.state.rentcostsState.dictionariesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentCostExamplePositionDataRequest();
    public currencies = currencies;

    get formTitle(): string {
        return this.edit ? 'Edytuj słownik' : 'Dodaj słownik';
    }

    get units() {
        return this.$store.state.settingsState.units;
    }

    get loadingUnits() {
        return this.$store.state.settingsState.loadingUnits;
    }

    get taxRates() {
        return this.$store.state.settingsState.taxRates;
    }

    get loadingTaxRates() {
        return this.$store.state.settingsState.loadingTaxRates;
    }

    get hasRequiredData() {
        return (
            this.itemData &&
            this.itemData.name &&
            this.itemData.name.length > 0 &&
            (!!this.itemData.unit || !!this.itemData.price || !!this.itemData.tax_rate)
        );
    }

    public created() {
        this.fetchUnits();
        this.fetchTaxRates();
    }

    public initForm() {
        this.form = this.$refs.createCostPositionDictionaryForm;
    }

    // HELPERS

    public isString(value: any) {
        return typeof value === 'string';
    }

    private fetchUnits() {
        this.$store.dispatch(settingsActionsTypes.FETCH_UNITS, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }

    private fetchTaxRates() {
        this.$store.dispatch(settingsActionsTypes.FETCH_TAX_RATES, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }
}
