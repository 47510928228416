
export class DocumentTemplateDataRequest {
    public id: number = 0;
    public name: string = '';
    public content: string = '';
    // tslint:disable-next-line:variable-name
    public public_status: string = '';
    // tslint:disable-next-line:variable-name
    public privileged_status: string = '';
    public tags: any = [];
    // tslint:disable-next-line:variable-name
    public category_id: number = 0;
}


