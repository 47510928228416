import {ListFilter} from '@/shared/types';

export const rentCostsListFilter: ListFilter[] = [

    {name: 'due_date', type: 'date', label: 'Termin Płatności', description: 'Wybierz zakres dat (od - do)'},
    {name: 'paid_at', type: 'date', label: 'Data Płatności', description: 'Wybierz zakres dat (od - do)'},
    {
        name: 'total_price_tax_excl',
        type: 'range',
        label: 'Koszt',
        description: 'Wybierz zakres kosztu faktury (bez VAT)',
    },
    {
        name: 'total_price_tax_incl',
        type: 'range',
        label: 'Koszt z VAT',
        description: 'Wybierz zakres kosztu faktury (z VAT)',
    },
];
