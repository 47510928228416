import {HeaderFieldType} from '@/shared/types';

export const rentCostsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nr',
        align: 'left',
        sortable: false,
        value: 'number',
    },
    {
        text: 'Najem',
        align: 'left',
        sortable: false,
        value: 'rent',
    },
    {
        text: 'Rodzaj',
        align: 'center',
        sortable: false,
        value: 'type',
    },
    {
        text: 'Kategorie',
        align: 'center',
        sortable: false,
        value: 'categories',
    },
    {
        text: 'Kwota',
        align: 'left',
        sortable: false,
        value: 'total_price_tax_excl',
    },
    {
        text: 'Kwota z Vat',
        align: 'left',
        sortable: false,
        value: 'total_price',
    },
    {
        text: 'Status',
        align: 'center',
        sortable: false,
        value: 'status',
    },
    {
        text: 'Akcje',
        align: 'center',
        sortable: false,
        value: 'actions'
    }

];

export const rentCostsCategoriesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },
];

export const rentCostExampleIssuersListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Adres',
        align: 'left',
        sortable: false,
        value: 'address',
    },
    {
        text: 'NIP',
        align: 'left',
        sortable: false,
        value: 'nip',
    },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },
];

export const rentCostPositionsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Ilość',
        align: 'left',
        sortable: false,
        value: 'quantity',
    },
    {
        text: 'Jednostka',
        align: 'left',
        sortable: false,
        value: 'unit',
    },
    {
        text: 'Cena',
        align: 'left',
        sortable: false,
        value: 'price',
    },
    {
        text: 'Podatek',
        align: 'left',
        sortable: false,
        value: 'tax_rate',
    },


];
export const rentCostExamplePositionsListHeaders: HeaderFieldType[] = [
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Jednostka',
        align: 'left',
        sortable: false,
        value: 'unit',
    },
    {
        text: 'Cena',
        align: 'left',
        sortable: false,
        value: 'price',
    },
    {
        text: 'Podatek',
        align: 'left',
        sortable: false,
        value: 'tax_rate',
    },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },


];

export const rentCostTypesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },
];
