
import { httpClient } from '@/shared/services';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

@Component
export default class NoInvestmentsBanner extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    public anyInvestmentExist = false;
    public loading = true;

    public created() {
        this.loading = true;
        httpClient.get('api/v1/investments/existence').then((res: AxiosResponse) => {
            this.anyInvestmentExist = !!res.data;
            this.$emit('existenceCheck', this.anyInvestmentExist);
            this.loading = false;
        });
    }
}
