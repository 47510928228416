
import { parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PositionTemplateAutocomplete from './position-examples-autocompelete.vue';
import { FormRules } from '@/shared/validation/form-rules';
import { currencies } from '@/shared/config/prices';
import SelectTree from '@/shared/components/elements/select-tree.vue';
import { rentCostExamplePositionsModule, rentCostPositionsModule } from '../../shared/state/submodules';
import { RentCostExamplePosition } from '../../shared/models/rent-cost-example-position';
import { RentCostExamplePositionDataRequest } from '../../shared/requests/rent-cost-example-position-data-request';
import { Inject } from 'vue-property-decorator';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { validatePosition } from '@/modules/rent-costs/shared/helpers/rent-cost-positions';
@Component({
    components: {
        PositionTemplateAutocomplete,
        SelectTree,
        TooltipBtn,
    },
})
export default class Position extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Prop(String) public itemClass!: string;
    @Prop(Object) public item!: any;
    @Prop(Array) public headers!: any;
    @Prop(Boolean) public edit!: boolean;
    @Prop (Boolean) public noEditPermissions!: boolean;

    public itemData = this.$props.item || {};
    public valid = false;
    public formRules = new FormRules();
    public parsePrice = parsePrice;
    public currencies = currencies;
    public examplePositionsModule = rentCostExamplePositionsModule;
    public storeExamplePositionAction = rentCostExamplePositionsModule.actionsTypes.STORE_ITEM;
    public actionsTypes = rentCostPositionsModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public autocompletion = false;
    public previews = {};
    public showExamplePositionForm = false;
    public saveExampleWith = {
        name: true,
        unit: true,
        price: true,
        tax_rate: true,
    };
    public isExamplePositionValid = true;

    get propertiesForExamplePosition() {
        const { name, unit, price, tax_rate } = this.itemData;
        return { name, unit, price, tax_rate };
    }

    get units() {
        return this.$store.state.settingsState.units;
    }

    get loadingUnits() {
        return this.$store.state.settingsState.loadingUnits;
    }

    get taxRates() {
        return this.$store.state.settingsState.taxRates.map((item: any) => item.rate);
    }

    get loadingTaxRates() {
        return this.$store.state.settingsState.loadingTaxRates;
    }

    get saveToExamplePositionDisabled() {
        for (const value of Object.values(this.propertiesForExamplePosition)) {
            if (value || value !== '') {
                return false;
            }
        }
        return true;
    }

    public created() {
        //
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`costs.positions.${action}`);
    }

    public onTemplateSelection(e: any) {
        Object.keys(e).forEach((key: string) => {
            if (!(key === 'id' || key === 'index') && e[key]) {
                this.$set(this.itemData, key, e[key]);
            }
        });
        this.onPropertyChange();
    }
    public priceTaxExcl(item: any) {
        return item.quantity * item.price;
    }

    public taxTotal(item: any) {
        return item.quantity * (item.price * ((Number(item.tax_rate) || 0) / 100));
    }

    public onPropertyChange() {
        if (!this.edit || !validatePosition(this.itemData) || this.autocompletion) {
            return;
        }
        const rentCostId = Number(this.$route.params.id);
        const requestData = { rentcost_id: rentCostId, ...this.itemData };
        if (typeof this.itemData.id === 'string' || this.itemData.id === null) {
            this.itemData.id = null;
            this.$store.dispatch(this.storeAction, requestData).then((res: any) => {
                this.$emit('update:item', res.data);
                this.itemData.id = res.data.id;
                this.$emit('updated');
            });
        } else {
            this.$store.dispatch(this.updateAction, requestData)
                .then(() => this.$emit('updated'));
        }
    }
    public showAutocompletion(position: RentCostExamplePosition) {
        this.autocompletion = false;
        setTimeout(() => {
            this.autocompletion = true;
        }, 100);
        for (const [key, value] of Object.entries(position)) {
            if (key === 'index' || key === 'id') {
                continue;
            }
            if (this.itemData[key] === value) {
                Vue.set(this.previews, key, null);
            } else {
                Vue.set(this.previews, key, value);
            }
        }
    }
    public clearAutocompletion(position: RentCostExamplePosition) {
        this.autocompletion = false;
    }

    public openExamplePositionForm() {
        this.showExamplePositionForm = true;
        this.saveExampleWith = {
            name: true,
            unit: !!this.propertiesForExamplePosition.unit,
            price: !!this.propertiesForExamplePosition.price,
            tax_rate: !!this.propertiesForExamplePosition.tax_rate,
        };
    }

    public isCheckboxReadonly(propertyKey: string) {
        if (!(this.propertiesForExamplePosition as any)[propertyKey]) {
            return true;
        }
        let isReadonly = true;
        for (const [key, value] of Object.entries(this.saveExampleWith)) {
            if (key === 'name' || key === propertyKey) {
                continue;
            }
            if (!value) {
                continue;
            } else {
                isReadonly = false;
                break;
            }
        }
        return isReadonly && (this.saveExampleWith as any)[propertyKey];
    }
    public cancelExamplePositonForm() {
        this.showExamplePositionForm = false;
    }
    public saveAsExamplePosition() {
        const requestData = {} as RentCostExamplePositionDataRequest;
        for (const [key, value] of Object.entries(this.propertiesForExamplePosition)) {
            if ((this.saveExampleWith as any)[key]) {
                Vue.set(requestData, key, value);
            }
        }
        this.$store
            .dispatch(this.storeExamplePositionAction, requestData)
            .then((res) => this.cancelExamplePositonForm());
    }

    public getInputLabel(key: string) {
        switch (key) {
            case 'name':
                return 'Nazwa';
            case 'unit':
                return 'Jednostka';
            case 'price':
                return 'Cena';
            case 'tax_rate':
                return 'Podatek';
        }
    }
    public getInputSuffix(key: string) {
        switch (key) {
            case 'price':
                return 'PLN';
            case 'tax_rate':
                return '%';
            default:
                return '';
        }
    }
}
