
import DetailsComponent from '@/shared/components/layout/details/details-component';
import Component from 'vue-class-component';
import { Cost } from '../../shared/models/cost';
import { costsModule } from '../../shared/state/module';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import CostEditForm from '@/modules/costs/components/form/edit-form/form.vue';
import AddressField from '@/shared/components/elements/address.vue';
import PositionsList from '@/modules/costs/components/details/positions-list.vue';
import AttachmentsSelector from '@/modules/costs/components/form/helpers/attachments-selector.vue';
import { invoicePaymentMethods } from '@/shared/config/invoices';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { costStatuses } from '@/shared/config/costs';
import { investmentObjectsModule } from '@/modules/investments/shared/state/submodules/objects';
import StatusPicker from '@/modules/costs/components/details/status-picker.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import { costAttachmentsModule } from '@/modules/costs/shared/state/submodules';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import { CostAttachment } from '../../shared/models/cost-attachment';
import { httpClient } from '@/shared/services';
import { CostAttachmentDataRequest } from '../../shared/requests/cost-attachment-data-request';
import { Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
    components: {
        TopToolbar,
        CostEditForm,
        AddressField,
        PositionsList,
        AttachmentsSelector,
        InvestmentTagChip,
        TextContrast,
        StatusPicker,
        TooltipBtn,
        ObservableWatch,
    },
})
export default class CostDetails extends DetailsComponent<Cost> {
    public currentTab = 0;
    public store = this.$store.state.costsState;
    public costsModule = costsModule;
    public actionsTypes = costsModule.actionsTypes;
    public mutationTypes = costsModule.mutationsTypes;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public updateAttachmentsAction = costAttachmentsModule.actionsTypes.STORE_ITEM;
    public fetchAttachmentsAction = costAttachmentsModule.actionsTypes.FETCH_DATA;
    public investmentObjectsActionTypes = investmentObjectsModule.actionsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public editedItem = {};
    public attachmentsCopy: CostAttachment[] | null = null;
    public attachmentsRequestData: CostAttachmentDataRequest[] = [];

    get formDialog() {
        return this.$store.state.costsState.formDialog;
    }

    set formDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get attachments() {
        return this.$store.state.costsState.attachmentsState.data;
    }

    set attachments(val: CostAttachment[]) {
        this.$set(this.$store.state.costsState.attachmentsState, 'data', val);
    }

    public created() {
        this.id = this.$route.params.id;
        this.fetchDetails();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`costs.${action}`);
    }

    public getPaymentMethodName(id: string) {
        return invoicePaymentMethods.find((el) => el.id === id)?.name;
    }

    public statusType(statusType: string) {
        return costStatuses.find((obj) => obj.id === statusType);
    }

    @Watch('attachments', { deep: true })
    public updateAttachments(items: CostAttachmentDataRequest[]) {
        if (!this.attachmentsCopy || !this.haveAttachmentsChanged()) { return; }

        httpClient
            .post(`/api/v1/costs/${this.id}/attachments`, { cost_id: this.item.id, attachments: items })
            .then((res) => {
                if (res.status === 204) {
                    this.$store.commit('SHOW_SNACKBAR', {
                        type: 'success',
                        text: 'Zaktualizowano załączniki',
                    });
                }
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    public fetchCallback(response: any) {
        this.$store.dispatch(this.investmentObjectsActionTypes.FETCH_DATA, {
            simple: true,
            filters: { investment_id: this.item.investment_id },
        });
        this.$store
            .dispatch(this.fetchAttachmentsAction, {
                id: this.id,
            })
            .then(({ data }) => {
                this.attachmentsCopy = _.cloneDeep(data);
            });
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public editItemToolbar() {
        this.formDialog = true;
        this.editedItem = { ...this.item };
    }

    public closeForm() {
        this.formDialog = false;
    }

    private haveAttachmentsChanged() {
        if (!this.attachmentsCopy) { return false; }
        return (
            this.attachments.length !== this.attachmentsCopy.length ||
            this.attachmentsCopy.filter(
                (attachment: any, index: number) =>
                    attachment.media_file_id !== this.attachments[index].media_file_id ||
                    attachment.type !== this.attachments[index].type,
            ).length > 0
        );
    }
}
