
import { Component, Watch, Prop } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import SubFormActions from './sub-form-actions.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';
import { rentCostsModule } from '../../shared/state/module';
import { RentCostDataRequest } from '../../shared/requests/rent-cost-data-request';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import { rentCostPositionsListHeaders } from '../../shared/config';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import Position from '@/modules/rent-costs/components/positions/position.vue';
import Draggable from 'vuedraggable';
import { logger } from '@/shared/services';
import { actionsTypes as settingsActionsTypes } from '@/modules/settings/shared/state';
import { parsePrice } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        SubFormActions,
        CardPicker,
        ListToolbar,
        Position,
        Draggable },
})
export default class RentCostsPositions extends Vue {
    @Prop(Object) public itemData!: RentCostDataRequest;
    @Prop(Object) public referencePhoto!: any;
    @Prop(Number) public step!: number;
    public actionsTypes = rentCostsModule.actionsTypes;
    public formRules = new FormRules();
    public store = this.$store.state.costsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public errorMessages: any = {};
    public showAddPositionForm = false;
    public parsePrice = parsePrice;
    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    };

    get positions() {
        return this.itemData.positions;
    }
    set positions(val) {
        this.itemData.positions = val;
    }

    get headers() {
        const headers = rentCostPositionsListHeaders;
        return [
            ...headers,
            { text: 'Cena netto', value: 'price' },
            { text: 'Kwota VAT', value: 'tax_total' },
            { text: 'Kwota brutto', value: 'total_price_tax_incl' },
            { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
        ];
    }

    get canNewItemBeCreated() {
        if (!this.positions) { return false; }
        const canBe = true;
        for (const position of this.positions) {
            if (!canBe) {
                break;
            }
        }
        return canBe;
    }

    get valid() {
        return this.canNewItemBeCreated && this.positions.length > 0;
    }

    get summedPriceTaxExcl() {
        if (this.positions.length === 0) {
            return 0;
        }
        let counter = 0;
        this.positions.forEach((el) => {
            counter += this.priceTaxExcl(el);
        });

        return counter;
    }
    get summedPriceTaxTotal() {
        if (this.positions.length === 0) {
            return 0;
        }
        let counter = 0;
        this.positions.forEach((el) => {
            counter += this.taxTotal(el);
        });

        return counter;
    }

    get summedPriceTaxIncl() {
        if (this.positions.length === 0) {
            return 0;
        }
        let counter = 0;
        this.positions.forEach((el) => {
            counter += this.priceTaxExcl(el) + this.taxTotal(el);
        });

        return counter;
    }

    public created() {
        this.fetchUnits();
        this.fetchTaxRates();
    }

    public displayAddPositionForm() {
        this.showAddPositionForm = true;
    }

    public createItem() {
        if (!this.positions) {
            this.positions = [];
        }
        const index: number = this.positions.length + 1;
        const newItem = {
            id: `${Math.random()}${new Date().toString()}`,
            index,
            name: '',
            unit: '',
            quantity: 1,
            currency: 'PLN' ,
            tax_rate: null,
            price: 1,
        } as any;
        this.positions.push(newItem as never);
    }

    public removePosition(e: any) {
        this.positions = this.positions.filter((el: any) => !this._.isEqual(el, e));
    }

    public onPositionDrop(e: any) {
        if (e.oldIndex === e.newIndex) {
            return;
        }
        this.positions.forEach((item: any, index) => {
            this.$set(item, 'index', index + 1);
        });
    }

    public priceTaxExcl(item: any) {
        return item.quantity * item.price;
    }

    public taxTotal(item: any) {
        return item.quantity * (item.price * ((Number(item.tax_rate) || 0) / 100));
    }

    private fetchUnits() {
        this.$store.dispatch(settingsActionsTypes.FETCH_UNITS, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }

    private fetchTaxRates() {
        this.$store.dispatch(settingsActionsTypes.FETCH_TAX_RATES, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }
}
