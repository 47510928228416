
import { Component, Watch } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import SidePanel from './side-panel.vue';
import DocumentsForm from '../form.vue';
import DetailsToolbar from '@/modules/documents/components/details/details-toolbar.vue';
import { DocumentTemplate } from '@/modules/documents/shared/models/document-template';
import { documentTemplatesModule } from '@/modules/documents/shared/state/submodules';
import { getUser } from '@/modules/admin/shared/services';

@Component({
    components: { DocumentEditor, TopToolbar, DocumentsForm, SidePanel, DetailsToolbar },
})
export default class DocumentsDetails extends DetailsComponent<DocumentTemplate> {
    public store: any = this.$store.state.documentsState.templatesState;
    public actionsTypes = documentTemplatesModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public showSidePanel = false;
    public restricted = true;
    public userCurrentlyEditing = null;
    public documentEditMutation = documentTemplatesModule.mutationsTypes.START_EDIT_DOCUMENT_TEMPLATE;

    get editedItem() {
        return this.store.editedItem;
    }
    set editedItem(val: any) {
        this.$set(this.store, 'editedItem', val);
    }

    get editedDocuments() {
        return this.store.editedDocuments;
    }

    get isCurrentlyEdited() {
        if (!this.editedDocuments || !this.id) {
            return false;
        }
        const ids: any[] = this.editedDocuments.map((el: any) => el.id);
        return ids.includes(this.id);
    }
    get isMobile() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs':
                return true;
            case 'sm':
                return true;
            case 'md':
                return false;
            case 'lg':
                return false;
            case 'xl':
                return false;
        }
    }

    get formDialog() {
        return this.store.formDialog;
    }
    set formDialog(val: any) {
        this.store.formDialog = val;
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }
    public setupForm({ restricted }: any) {
        this.editedItem = this._.cloneDeep(this.item);
        this.restricted = restricted;
        this.formDialog = true;
    }

    @Watch('formDialog')
    public onFormDialogChange(val: boolean) {
        if (!val) {
            return;
        }
        this.$store.commit(this.documentEditMutation, this.id);
    }

    public created() {
        this.fetchDetails();
    }

    @Watch('isCurrentlyEdited', { immediate: true })
    public async onLiveEdition(val: boolean) {
        this.userCurrentlyEditing = null;
        if (!val) {
            return;
        }
        const userID = this.editedDocuments.find((el: any) => Number(el.id) === Number(this.id)).userId;
        this.userCurrentlyEditing = (await getUser(userID)).data;
    }
}
