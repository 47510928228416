
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentCostExampleIssuerDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-example-issuer-data-request';
import { rentCostExampleIssuersModule } from '@/modules/rent-costs/shared/state/submodules';

@Component({
    components: {
        FormActions
    }
})
export default class ExampleIssuersForm extends FormComponent<RentCostExampleIssuerDataRequest> {
    public actionsTypes = rentCostExampleIssuersModule.actionsTypes;
    public store = this.$store.state.rentcostsState.exampleIssuersState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentCostExampleIssuerDataRequest();

    get formTitle(): string {
        return this.edit ? 'Edytuj słownik' : 'Dodaj słownik';
    }

    public initForm() {
        this.form = this.$refs.CreateCostIssuerDictionaryForm;
    }
}

