export const rentCostStatuses = [
    {
        id: 'new', name: 'Nowy', color: '#00bdb0', namePlural: 'Nowe', setAfter: [],
    },
    {
        id: 'approved', name: 'Zatwierdzony', color: '#3aba67', namePlural: 'Zatwierdzone', setAfter: ['new'],
    },
    {
        id: 'to-explain', name: 'Do wyjaśnienia', color: '#e3d407', namePlural: 'Do wyjaśnienia', setAfter: ['new'],
    },
    {
        id: 'explained', name: 'Wyjaśniony', color: '#248229', namePlural: 'Wyjaśnione', setAfter: ['to-explain'],
    },
    {
        id: 'rejected',
        name: 'Odrzucony',
        color: '#cc301f',
        namePlural: 'Odrzucone',
        closed: true,
        setAfter: ['new', 'to-explain'],
    },
    {
        id: 'paid', name: 'Opłacony', color: '#2d5ff7', namePlural: 'Opłacone', setAfter: ['explained', 'approved'],
    },
    {
        id: 'settled', name: 'Rozliczony', color: '#8042eb', namePlural: 'Rozliczone', setAfter: ['paid'],
    },
    {
        id: 'closed',
        name: 'Zamknięty',
        color: '#c9c9c9',
        namePlural: 'Zamnknięte',
        closed: true,
        setAfter: ['settled'],
    },

];

export const costAttachmentsTypes = [
    {
        id: 'advance-invoice', name: 'FVAT - zaliczkowa'
    },
    {
        id: 'final-invoice', name: 'FVAT - końcowa'
    },
    {
        id: 'corrective-invoice', name: 'FVAT - korygująca'
    },
    {
        id: 'invoice', name: 'FVAT'
    },
    {
        id: 'account', name: 'Rachunek'
    },
    {
        id: 'debit-note', name: 'Nota obciążeniowa'
    },
    {
        id: 'payroll', name: 'Lista płac'
    },
    {
        id: 'other', name: 'Inne'
    },
];
