
import { Component, Watch } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { rentCostsListHeaders } from '@/modules/rent-costs/shared/config';
import { rentCostsModule } from '@/modules/rent-costs/shared/state/module';
import RentCostForm from '@/modules/rent-costs/components/form/form.vue';
import { rentCostsListFilter } from '@/modules/rent-costs/shared/config/filters';
import { RentCost } from '../shared/models/rent-cost';
import { RentCostDataRequest } from '../shared/requests/rent-cost-data-request';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { rentCostTypesModule } from '../shared/state/submodules';
import { rentCostCategoriesModule as categoriesModule } from '@/modules/rent-costs/shared/state/submodules';
import { ListFilter } from '@/shared/types';
import { rentCostStatuses } from '@/shared/config/rent-costs';
import RentCostEditForm from '@/modules/rent-costs/components/form/edit-form/form.vue';
import StatusPicker from '@/modules/rent-costs/components/details/status-picker.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { callSuper } from '@/shared/helpers';
import { rentsModule } from '@/modules/rents/shared/state/state';
@Component({
    components: {
        StatusPicker,
        RentCostForm,
        ItemsTable,
        TextContrast,
        RentCostEditForm,
        TooltipBtn,
    },
})
export default class RentCostsList extends ListComponent<RentCost, RentCostDataRequest> {
    public headers: object[] = rentCostsListHeaders;
    public actionsTypes = rentCostsModule.actionsTypes;
    public mutationTypes = rentCostsModule.mutationsTypes;
    public props: ModuleProps = rentCostsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.rentcostsState;
    public loadingFilters = false;
    public filterConfig = rentCostsListFilter;
    public containerDefaultMaxHeight!: any;
    public filters = [] as ListFilter[];
    public newRentCost = {
        positions: [
            {
                id: `${Math.random()}${new Date().toString()}`,
                index: 1,
                name: '',
                unit: '',
                quantity: 1,
                currency: 'PLN',
                tax_rate: null,
                price: 1,
            } as any,
        ] as any,
    } as RentCost;

    public rentsFetchAction = rentsModule.actionsTypes.FETCH_DATA;
    public rentCostTypesFetchAction = rentCostTypesModule.actionsTypes.FETCH_DATA;
    public categoriesFetchAction = categoriesModule.actionsTypes.FETCH_DATA;

    get editFormDialog() {
        return this.$store.state.rentcostsState.editFormDialog;
    }

    set editFormDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDIT_FORM_DIALOG, value);
    }

    get toolbarProps() {
        if (this.anyRentExist) {
            return undefined;
        }

        return {
            addButtonTooltip: 'Brak najmów',
            disabled: true,
        };
    }

    get anyRentExist() {
        return !this.loading && this.$store.state.rentsState.data &&
            this.$store.state.rentsState.data.length > 0 ? true : false;
    }

    public closeEditForm() {
        this.editFormDialog = false;
        this.error = '';
        this.errorMessages = {};
    }

    public editItem(item: RentCost) {
        const editedItem: any = Object.assign({}, item as RentCost);
        this.editedItem = editedItem as RentCostDataRequest;
        this.editFormDialog = true;
    }

    public itemSaved() {
        this.editFormDialog = false;
        callSuper(this, 'itemSaved');
    }

    public beforeMount() {
        this.formDialog = false;
    }

    public mounted() {
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    public closeForm() {
        callSuper(this, 'closeForm');
        this.newRentCost = {
            positions: [
                {
                    id: `${Math.random()}${new Date().toString()}`,
                    index: 1,
                    name: '',
                    unit: '',
                    quantity: 1,
                    tax_rate: null,
                    price: 1,
                } as any,
            ] as any,
        } as RentCost;
    }

    public async fetchFilters() {
        function convertToFilters(item: any) {
            return item.map((el: any) => {
                return {
                    label: el.name,
                    name: el.id,
                    value: false,
                };
            });
        }
        const { data: rents } = await this.$store.dispatch(this.rentsFetchAction, { simple: false });
        const { data: rentCostTypes } = await this.$store.dispatch(this.rentCostTypesFetchAction, { simple: false });
        const { data: categories } = await this.$store.dispatch(this.categoriesFetchAction, { simple: false });
        this.filters = [
            { name: 'rent_id', label: 'Najem', type: 'search', data: rents },
            { name: 'type_id', label: 'Rodzaj', type: 'search', data: rentCostTypes },
            { name: 'categories', label: 'Kategorie', type: 'search', data: categories },
            { name: 'status', label: 'Status', type: 'select', data: convertToFilters(rentCostStatuses) },
            ...this.filterConfig,
        ];
        this.loadingFilters = false;
    }

    public created() {
        this.loadingFilters = true;
        this.fetchFilters();
    }

    public statusType(statusType: string) {
        return rentCostStatuses.find((obj) => obj.id === statusType);
    }

    public onStatusChange(item: RentCost, e: any) {
        this.$store.dispatch(this.updateAction, { id: item.id, status: e }).then((res) => {
            if (res && res.data) {
                item.status = res.data.status;
            }
        });
    }
}
