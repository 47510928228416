
import DetailsComponent from '@/shared/components/layout/details/details-component';
import Component from 'vue-class-component';
import { RentCost } from '../../shared/models/rent-cost';
import { rentCostsModule } from '../../shared/state/module';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import RentCostEditForm from '@/modules/rent-costs/components/form/edit-form/form.vue';
import AddressField from '@/shared/components/elements/address.vue';
import PositionsList from '@/modules/rent-costs/components/details/positions-list.vue';
import AttachmentsSelector from '@/modules/rent-costs/components/form/helpers/attachments-selector.vue';
import { invoicePaymentMethods } from '@/shared/config/invoices';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { costStatuses } from '@/shared/config/costs';
import StatusPicker from '@/modules/rent-costs/components/details/status-picker.vue';
import { rentCostAttachmentsModule } from '@/modules/rent-costs/shared/state/submodules';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';


@Component({
    components: {
        TopToolbar,
        RentCostEditForm,
        AddressField,
        PositionsList,
        AttachmentsSelector,
        TextContrast,
        StatusPicker,
        TooltipBtn,
        ObservableWatch,
    },
})
export default class RentCostDetails extends DetailsComponent<RentCost> {
    public currentTab = 0;
    public store = this.$store.state.rentcostsState;
    public costsModule = rentCostsModule;
    public actionsTypes = rentCostsModule.actionsTypes;
    public mutationTypes = rentCostsModule.mutationsTypes;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public updateAttachmentsAction = rentCostAttachmentsModule.actionsTypes.STORE_ITEM;
    public fetchAttachmentsAction = rentCostAttachmentsModule.actionsTypes.FETCH_DATA;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public editedItem = {};

    get formDialog() {
        return this.$store.state.rentcostsState.formDialog;
    }

    set formDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get totalPrice() {
        return this.store.current.total_price;
    }

    get noTaxPrice() {
        return this.store.current.total_price_tax_excl;
    }

    public getPaymentMethodName(id: string) {
        return invoicePaymentMethods.find((el) => el.id === id)?.name;
    }

    public statusType(statusType: string) {
        return costStatuses.find((obj) => obj.id === statusType);
    }

    public created() {
        this.id = this.$route.params.id;
        this.fetchDetails();
        /* */
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`costs.${action}`);
    }

    public updateAttachments(items: any[]) {
        this.$store.dispatch(this.updateAttachmentsAction, { cost_id: this.item.id, attachments: items });
    }

    public fetchCallback(response: any) {
        this.$store.dispatch(this.fetchAttachmentsAction, {
            id: this.id,
        });
    }

    get attachments() {
        return this.$store.state.rentcostsState.attachmentsState.data;
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public editItemToolbar() {
        this.formDialog = true;
        this.editedItem = { ...this.item };
    }

    public closeForm() {
        this.formDialog = false;
    }

}
