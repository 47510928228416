
import { RentCostDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-data-request';
import { rentCostsModule } from '@/modules/rent-costs/shared/state/module';
import { rentCostTypesModule, rentCostCategoriesModule, rentCostExampleIssuersModule } from '@/modules/rent-costs/shared/state/submodules';
import { invoicePaymentMethods } from '@/shared/config/invoices';
import { FormRules } from '@/shared/validation/form-rules';
import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import FormComponent from '@/shared/components/layout/form/form-component';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { httpClient } from '@/shared/services';
import { callSuper } from '@/shared/helpers';
import NameReduce from '@/shared/components/elements/name-reduce.vue';

@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
        DatePicker,
        TextContrast,
        NameReduce,
    },
})
export default class RentCostEditForm extends FormComponent<RentCostDataRequest> {
    public requestData: RentCostDataRequest = new RentCostDataRequest();

    get formTitle() {
        return 'Edytuj Koszt';
    }

    get categories() {
        return this.$store.state.rentcostsState.categoriesState.data;
    }

    get loadingCategories() {
        return this.$store.state.rentcostsState.categoriesState.loading;
    }

    get costTypes() {
        return this.$store.state.rentcostsState.typesState.data;
    }

    get loadingCostTypes() {
        return this.$store.state.rentcostsState.typesState.loading;
    }
    public menu = false;
    public actionsTypes = rentCostsModule.actionsTypes;
    public formRules = new FormRules();
    public valid = false;
    public store = this.$store.state.rentcostsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
    public paymentMethods = invoicePaymentMethods;
    public fetchIssuersAction = rentCostExampleIssuersModule.actionsTypes.FETCH_DATA;
    public typesModule = rentCostTypesModule;
    public typesActionsTypes = rentCostTypesModule.actionsTypes;
    public pickedName = '';
    public categoriesModule = rentCostCategoriesModule;
    public categoriesActionTypes = rentCostCategoriesModule.actionsTypes;
    public categoriesValues = {};
    public inputTimer: any = {};

    public numberErrors = [] as string[];
    public itemOrginalNumber = '';

    get issuers() {
        return this.$store.state.rentcostsState.exampleIssuersState.data;
    }

    public initForm() {
        this.form = this.$refs.editCostForm;
        this.categoriesValues = this.itemData.categories;
         }

    public created() {
        this.itemOrginalNumber = this.itemData.number;
        this.$store.dispatch(this.typesActionsTypes.FETCH_DATA, { simple: false });
        this.$store.dispatch(this.categoriesActionTypes.FETCH_DATA, { simple: false });
        this.fetchIssuers();
    }

    public fetchIssuers() {
        this.$store.dispatch(this.fetchIssuersAction, {search: this.itemData.issuer_name})
            .then((data) => {
                this.$store.state.rentcostsState.exampleIssuersState.data = data.data;
            });
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public async submitForm(e: any, next?: boolean) {
        this.updateCategories(this.categoriesValues);
        callSuper(this, 'submitForm', e, next);
    }

    public updateCategories(event: any) {
        this.$set(this.itemData, 'categories', [...event.map((cat: any) => cat.id)]);
    }

    public issuerData(item: any) {
        this.itemData.issuer_name = item.name;
        this.itemData.issuer_address = item.address;
        this.itemData.nip = item.nip;
        this.pickedName = item.name;
    }

    @Watch('itemData.number')
    public validateNumber(v: string) {
        this.numberErrors.pop();
        if (v === this.itemOrginalNumber) {
            return;
        }
        httpClient.post('/api/v1/costs/validate-number', { number: v }).catch((err: any) => {
            if (err.errors.number[0] === 'Wartość pola Numer została już użyta.') {
                this.numberErrors.push('Numer jest już zajęty');
            }
        });
    }

    @Watch('itemData.issuer_name')
    public onIssuerDataChange() {
        clearTimeout(this.inputTimer);
        if (this.itemData.issuer_name !== this.pickedName) {
            this.inputTimer = setTimeout(() => {
                this.fetchIssuers();
            }, 500);
        }
    }

}
