
import Vue from 'vue';
import { Component, Prop} from 'vue-property-decorator';
@Component({
})
export default class SubFormActions extends Vue {
    @Prop(Number) public step!: number;
    @Prop(Boolean) public valid!: boolean;
    @Prop(Boolean) public optional!: boolean;
    get canGoBack() {
        return this.step > 1;
    }
}
