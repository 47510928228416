import { FormRules } from '@/shared/validation/form-rules';
import { CostPosition } from '../models/cost-position';

const formRules = new FormRules();

export function validatePosition(position: CostPosition) {
    let valid = true;
    for (const [key, value] of Object.entries(position)) {
        if (key === 'investment_object_id' || key === 'id') {
            continue;
        }
        if (typeof (formRules.required as (arg0: any) => any)(value) === 'string') {
            valid = false;
        }
        if (!valid) {
            break;
        }
    }
    return valid;
}
