
export class RentCostDataRequest {
    public id = 0;
    public number = '';
    public price = 0;
    // tslint:disable-next-line:variable-name
    public due_date = '';
    // tslint:disable-next-line:variable-name
    public paid_at = '';
    // tslint:disable-next-line:variable-name
    public payment_method = '';
    // tslint:disable-next-line:variable-name
    public issuer_name = '';
    // tslint:disable-next-line:variable-name
    public issuer_address = '';
    // tslint:disable-next-line:variable-name
    public nip = '';
    // tslint:disable-next-line:variable-name
    public description = '';
    public status = '';
    // tslint:disable-next-line:variable-name
    public issue_date: string = '';
    // tslint:disable-next-line:variable-name
    public sale_date: string = '';
    // tslint:disable-next-line:variable-name
    // tslint:disable-next-line:variable-name
    public categories = [] as number[];
    // tslint:disable-next-line:variable-name
    public rent_id = 0;
    // tslint:disable-next-line:variable-name
    public attachments = [];
    // tslint:disable-next-line:variable-name
    public positions = [];
    // tslint:disable-next-line:variable-name
    public type_id = 0;
    // tslint:disable-next-line:variable-name
    // public department_id = 0;


}
