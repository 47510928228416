
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    costExamplePositionsListHeaders,
} from '@/modules/costs/shared/config';
import { costExamplePositionsModule } from '@/modules/costs/shared/state/submodules';
import ExamplePositionForm from '@/modules/costs/components/settings/example-positions/form.vue';
import { CostExamplePosition } from '@/modules/costs/shared/models/cost-example-position';
import { CostExamplePositionDataRequest } from '@/modules/costs/shared/requests/cost-example-position-data-request';

@Component({
    components: {
        ExamplePositionForm,
        ItemsTable,
    },
})
export default class ExamplePositionsList extends ListComponent<
    CostExamplePosition,
    CostExamplePositionDataRequest
> {
    public headers: object[] = costExamplePositionsListHeaders;
    public actionsTypes = costExamplePositionsModule.actionsTypes;
    public mutationTypes = costExamplePositionsModule.mutationsTypes;
    public props: ModuleProps = costExamplePositionsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.costsState.examplePositionsState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }


    public closeForm() {
        this.editedItem = {} as CostExamplePositionDataRequest;
        this.formDialog = false;
    }
}
