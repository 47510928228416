
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    rentCostExamplePositionsListHeaders,
} from '@/modules/rent-costs/shared/config';
import { rentCostExamplePositionsModule } from '@/modules/rent-costs/shared/state/submodules';
import ExamplePositionForm from '@/modules/rent-costs/components/settings/example-positions/form.vue';
import { RentCostExamplePosition } from '@/modules/rent-costs/shared/models/rent-cost-example-position';
import { RentCostExamplePositionDataRequest
    } from '@/modules/rent-costs/shared/requests/rent-cost-example-position-data-request';

@Component({
    components: {
        ExamplePositionForm,
        ItemsTable,
    },
})
export default class ExamplePositionsList extends ListComponent<
    RentCostExamplePosition,
    RentCostExamplePositionDataRequest
> {
    public headers: object[] = rentCostExamplePositionsListHeaders;
    public actionsTypes = rentCostExamplePositionsModule.actionsTypes;
    public mutationTypes = rentCostExamplePositionsModule.mutationsTypes;
    public props: ModuleProps = rentCostExamplePositionsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.rentcostsState.examplePositionsState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }


    public closeForm() {
        this.editedItem = {} as RentCostExamplePositionDataRequest;
        this.formDialog = false;
    }
}
