
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
// import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

import { CategoryForm, TagForm, PriorityForm, StatusForm } from '@/modules/tickets/components';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { actionsTypes } from '@/modules/admin/shared/state';
import { User } from '@/modules/admin/shared/models/user';
import { httpClient } from '@/shared/services';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import { CostCategoryDataRequest } from '@/modules/costs/shared/requests/cost-category-data-request';
import { costCategoriesModule } from '@/modules/costs/shared/state/submodules';
import { callSuper } from '@/shared/helpers';


@Component({
    components: {
        FormActions,
    },
})
export default class CostsCategoryForm extends FormComponent<CostCategoryDataRequest> {
    public actionsTypes = costCategoriesModule.actionsTypes;
    public store: any = this.$store.state.costsState.categoriesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new CostCategoryDataRequest();


    public swatches: Array<[string, string]> = [
        ['#753BBD', '#147BD1'],
        ['#2DC84D', '#FF7F41'],
        ['#F7EA48', '#ea97ad'],
        ['#4A6695', '#E03C31'],
        ['#B7CC33', '#FF00A5'],
    ];

    // for autocomplete with add new

    public created() {
        /**/
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj kategorię' : 'Dodaj kategorię';
    }

    public initForm() {
        this.form = this.$refs.createCostCategoryForm;
         }

    // HELPERS

    public isString(value: any) {
        return typeof value === 'string';
    }

    @Watch('itemData.color')
    public ifColorObject() {
        if (!(typeof this.itemData.color === 'string')) { this.itemData.color = '#147BD1'; }
    }
}
