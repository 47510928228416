
import { Component, Inject, Watch } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import SubFormActions from './sub-form-actions.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';
import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state';
import { costsModule } from '../../shared/state/module';
import { CostDataRequest } from '../../shared/requests/cost-data-request';
import { contractorsModule } from '@/modules/contractors/shared/state/module';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import ContractorsForm from '@/modules/contractors/components/form.vue';
import { costCategoriesModule, costTypesModule } from '@/modules/costs/shared/state/submodules';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { invoicePaymentMethods } from '@/shared/config/invoices';
import { departmentsModule } from '@/modules/departments/shared/state/module';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { httpClient } from '@/shared/services';

@Component({
    components: { FormActions, SubFormActions, AutoCompleteWithAddNew, DatePicker, TextContrast },
    props: {
        itemData: Object,
        referencePhoto: Object,
        step: Number,
    },
})
export default class AcceptanceFormEntryData extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    public actionsTypes = costsModule.actionsTypes;
    public formRules = new FormRules();
    public valid = false;
    public itemData!: CostDataRequest;
    public store = this.$store.state.acceptancesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public fetchUsersAction = adminActionsTypes.FETCH_USER_DATA;
    public errorMessages: any = {};
    public paymentMethods = invoicePaymentMethods;
    public searchDepartments = '';
    public ContractorsForm = ContractorsForm;
    public contractorsModule = contractorsModule;
    public contractorsState = this.$store.state.contractorsState;
    public contractorsActionsTypes = contractorsModule.actionsTypes;
    public contractorsMutationsTypes = contractorsModule.mutationsTypes;
    public autocompleteBlur = true;
    public numberErrors = [] as string[];

    public costTypesModule = costTypesModule;
    public costTypesActionsTypes = costTypesModule.actionsTypes;

    public costCategoriesModule = costCategoriesModule;
    public costCategoriesActionsTypes = costCategoriesModule.actionsTypes;

    public departmentsModule = departmentsModule;
    public departmentsActionsTypes = departmentsModule.actionsTypes;

    @Watch('itemData.contractor_id', { deep: true })
    public resetAutocompleteWithAddNewValidation() {
        ((this.$refs.autoCompleteWithAddNew as Vue).$children[0] as any).resetValidation();
    }

    @Watch('searchDepartments')
    public onSearchDepartments(val: string) {
        if (this.loadingDepartments || this.autocompleteBlur || val === '') {
            return;
        }
        setTimeout(() => {
            this.$store.dispatch(this.departmentsActionsTypes.FETCH_DATA, { simple: false, filters: { search: val } });
        }, 500);
    }

    get departments() {
        return this.$store.state.departmentsState.data;
    }
    get loadingDepartments() {
        return this.$store.state.departmentsState.loading;
    }

    get categories() {
        return this.$store.state.costsState.categoriesState.data;
    }
    get loadingCategories() {
        return this.$store.state.costsState.categoriesState.loading;
    }

    get costTypes() {
        return this.$store.state.costsState.typesState.data;
    }

    get loadingCostTypes() {
        return this.$store.state.costsState.typesState.loading;
    }

    public created() {
        this.$store.dispatch(this.costTypesActionsTypes.FETCH_DATA, { simple: false });
        this.$store.dispatch(this.costCategoriesActionsTypes.FETCH_DATA, { simple: false });
        this.$store.dispatch(this.departmentsActionsTypes.FETCH_DATA, { simple: false });
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public updateTags(event: any) {
        this.$set(this.itemData, 'categories', [...event.map((cat: any) => cat.id)]);
    }

    @Watch('itemData.number')
    public validateNumber(v: string) {
        this.numberErrors.pop();
        httpClient.post('/api/v1/costs/validate-number', { number: v }).catch((err: any) => {
            if (err.errors.number[0] === 'Wartość pola Numer została już użyta.') {
                this.numberErrors.push('Numer jest już zajęty');
            }
        });
    }
}
