
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { rentCostsCategoriesListHeaders } from '@/modules/rent-costs/shared/config';
import { rentCostCategoriesModule } from '@/modules/rent-costs/shared/state/submodules';
import RentCostCategoryForm from '@/modules/rent-costs/components/settings/categories/form.vue';
import { RentCostCategory } from '@/modules/rent-costs/shared/models/rent-cost-category';
import { RentCostCategoryDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-category-data-request';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

@Component({
    components: {
        RentCostCategoryForm,
        ItemsTable,
        TextContrast,
    },
})
export default class RentCostsList extends ListComponent<RentCostCategory,
    RentCostCategoryDataRequest> {
    public headers: object[] = rentCostsCategoriesListHeaders;
    public actionsTypes = rentCostCategoriesModule.actionsTypes;
    public mutationTypes = rentCostCategoriesModule.mutationsTypes;
    public props: ModuleProps = rentCostCategoriesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.costsState.categoriesState;
    public containerDefaultMaxHeight!: any;

    public mounted() {
        this.formDialog = false;
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    // @Watch('formDialog')
    // public onFormDialog(val: any) {
    //     if (val) { (this.$refs.container as HTMLElement).style.maxHeight = '100vh';
    //     } else { (this.$refs.container as HTMLElement).style.maxHeight = this.containerDefaultMaxHeight; }
    // }

    // public itemSaved() {
    //     this.fetchData();
    //     this.closeForm();
    // }

    public closeForm() {
        this.editedItem = {} as RentCostCategoryDataRequest;
        this.formDialog = false;
    }
}
