var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"pa-0 ma-0",staticStyle:{"position":"absolute","left":"0","top":"0","width":"100%"}},[_c('v-expand-transition',[(_vm.isCurrentlyEdited && _vm.userCurrentlyEditing)?_c('v-card',{staticClass:"ma-0 pa-0 shrink",attrs:{"flat":"","tile":"","height":"40"}},[_c('v-alert',{staticClass:"ma-0 shrink",attrs:{"dense":"","tile":"","border":"left","type":"warning"},model:{value:(_vm.isCurrentlyEdited),callback:function ($$v) {_vm.isCurrentlyEdited=$$v},expression:"isCurrentlyEdited"}},[_c('v-avatar',{attrs:{"size":"22"}},[_c('v-img',{attrs:{"lazy-src":"/assets/avatar.png","src":_vm.userCurrentlyEditing.avatar_photo
                                    ? _vm.userCurrentlyEditing.avatar_photo
                                    : '/assets/avatar.png'}})],1),_c('strong',[_vm._v(" "+_vm._s(_vm.userCurrentlyEditing.name)+" ")]),_vm._v(" aktualnie edytuje ten dokument ")],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","no-click-animation":"","retain-focus":false},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('documents-form',{attrs:{"item-data":_vm.editedItem,"edit":true,"restricted":_vm.restricted},on:{"next":function($event){_vm.formDialog = true},"saved":function($event){return _vm.itemSaved()}}})],1),_c('v-progress-linear',{staticClass:"ma-0",staticStyle:{"top":"0","position":"absolute"},attrs:{"active":_vm.loading,"indeterminate":true,"height":"2"}}),_c('div',{staticClass:"ma-0 pa-0 d-flex flex-column align-center justify-start",staticStyle:{"width":"100%","height":"100%"}},[_c('details-toolbar',{attrs:{"item":_vm.item,"loading":_vm.loading,"for-template":"","isCurrentlyEdited":_vm.isCurrentlyEdited},on:{"showForm":function($event){return _vm.setupForm($event)}}}),_c('div',{staticClass:"d-flex justify-start fill-height",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('document-editor',{staticClass:"fill-height",style:(`width: 100%; max-height: calc(100vh - ${
                            _vm.isCurrentlyEdited && _vm.userCurrentlyEditing ? '152px' : '112px'
                        }); transition: all 200ms ease`),attrs:{"previewMode":""},model:{value:(_vm.item.content),callback:function ($$v) {_vm.$set(_vm.item, "content", $$v)},expression:"item.content"}})],1),(!_vm.isMobile)?_c('side-panel',{style:(`max-width: 300px; height: calc(100vh - ${
                        _vm.isCurrentlyEdited && _vm.userCurrentlyEditing ? '152px' : '112px'
                    }); transition: all 200ms ease`),attrs:{"item":_vm.item}}):_vm._e()],1),_c('div',{staticClass:"pa-0 ma-0",staticStyle:{"max-height":"0px"}}),_c('v-fade-transition',[(_vm.isMobile && !_vm.showSidePanel)?_c('v-btn',{staticStyle:{"z-index":"1999"},attrs:{"fab":"","fixed":"","right":"","bottom":""},on:{"click":function($event){_vm.showSidePanel = true}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-scroll-x-reverse-transition',[(_vm.showSidePanel && _vm.isMobile)?_c('side-panel',{staticStyle:{"position":"fixed","top":"0","right":"0","z-index":"1999"},attrs:{"item":_vm.item}},[(_vm.isMobile)?_c('v-btn',{staticStyle:{"z-index":"1999"},attrs:{"fab":"","fixed":"","right":"","bottom":""},on:{"click":function($event){_vm.showSidePanel = false}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }