
import { parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import PositionTemplateAutocomplete from './position-examples-autocompelete.vue';
import { FormRules } from '@/shared/validation/form-rules';
import { currencies } from '@/shared/config/prices';
import SelectTree from '@/shared/components/elements/select-tree.vue';
import { costExamplePositionsModule, costPositionsModule } from '../../shared/state/submodules';
import { validatePosition } from '@/modules/costs/shared/helpers/cost-positions';
import { CostExamplePosition } from '../../shared/models/cost-example-position';
import { CostExamplePositionDataRequest } from '../../shared/requests/cost-example-position-data-request';
import { Inject } from 'vue-property-decorator';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        PositionTemplateAutocomplete,
        SelectTree,
        TooltipBtn,
    },
    props: {
        itemClass: {
            type: String,
            default: '',
        },
        item: {
            type: Object,
            default: () => {
                return {};
            },
        },
        headers: {
            type: Array,
            default: () => {
                return [];
            },
        },
        edit: Boolean,
        noEditPermissions: {
            type: Boolean,
            default: false,
        },
    },
})
export default class Position extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    public itemData = this.$props.item || {};
    public valid = false;
    public formRules = new FormRules();
    public parsePrice = parsePrice;
    public currencies = currencies;
    public examplePositionsModule = costExamplePositionsModule;
    public storeExamplePositionAction = costExamplePositionsModule.actionsTypes.STORE_ITEM;
    public actionsTypes = costPositionsModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public autocompletion = false;
    public previews = {};
    public showExamplePositionForm = false;
    public saveExampleWith = {
        name: true,
        unit: true,
        price: true,
        tax_rate: true,
    };
    public isExamplePositionValid = true;

    get propertiesForExamplePosition() {
        const { name, unit, price, tax_rate } = this.itemData;
        return { name, unit, price, tax_rate };
    }
    get objects() {
        return [
            { id: -1, name: 'Inwestycja', parent: '', children: null },
            ...this.$store.state.investmentsState.objectsState.dataList,
        ];
    }

    get objectsLoading() {
        return this.$store.state.investmentsState.objectsState.loading;
    }

    get units() {
        return this.$store.state.settingsState.units;
    }

    get loadingUnits() {
        return this.$store.state.settingsState.loadingUnits;
    }

    get taxRates() {
        return this.$store.state.settingsState.taxRates.map((item: any) => item.rate);
    }

    get loadingTaxRates() {
        return this.$store.state.settingsState.loadingTaxRates;
    }

    get saveToExamplePositionDisabled() {
        for (const value of Object.values(this.propertiesForExamplePosition)) {
            if (value || value !== '') {
                return false;
            }
        }
        return true;
    }

    public created() {
        this.itemData.investment_object_id = this.itemData.investment_object_id || -1;
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`costs.positions.${action}`);
    }

    public onTemplateSelection(e: any) {
        Object.keys(e).forEach((key: string) => {
            if (!(key === 'id' || key === 'index') && e[key]) {
                this.$set(this.itemData, key, e[key]);
            }
        });
        this.onPropertyChange();
    }
    public priceTaxExcl(item: any) {
        return item.quantity * item.price;
    }

    public taxTotal(item: any) {
        return item.quantity * (item.price * ((Number(item.tax_rate) || 0) / 100));
    }

    public onPropertyChange() {
        if (!this.$props.edit || !validatePosition(this.itemData) || this.autocompletion) {
            return;
        }
        const costId = Number(this.$route.params.id);
        const requestData = { cost_id: costId, ...this.itemData };
        if (requestData.investment_object_id === -1) {
            requestData.investment_object_id = null;
        }
        if (typeof this.itemData.id === 'string' || this.itemData.id === null) {
            this.itemData.id = null;
            this.$store.dispatch(this.storeAction, requestData).then((res: any) => {
                this.$emit('update:item', res.data);
                this.$set(this.itemData, 'id', res.data.id);
            }
            );
        } else {
            this.$store.dispatch(this.updateAction, requestData);
        }
        this.$emit('update');
    }
    public showAutocompletion(position: CostExamplePosition) {
        this.autocompletion = false;
        setTimeout(() => {
            this.autocompletion = true;
        }, 100);
        for (const [key, value] of Object.entries(position)) {
            if (key === 'index' || key === 'id') {
                continue;
            }
            if (this.itemData[key] === value) {
                Vue.set(this.previews, key, null);
            } else {
                Vue.set(this.previews, key, value);
            }
        }
    }
    public clearAutocompletion(position: CostExamplePosition) {
        this.autocompletion = false;
    }

    public openExamplePositionForm() {
        this.showExamplePositionForm = true;
        this.saveExampleWith = {
            name: true,
            unit: !!this.propertiesForExamplePosition.unit,
            price: !!this.propertiesForExamplePosition.price,
            tax_rate: !!this.propertiesForExamplePosition.tax_rate,
        };
    }

    public isCheckboxReadonly(propertyKey: string) {
        if (!(this.propertiesForExamplePosition as any)[propertyKey]) {
            return true;
        }
        let isReadonly = true;
        for (const [key, value] of Object.entries(this.saveExampleWith)) {
            if (key === 'name' || key === propertyKey) {
                continue;
            }
            if (!value) {
                continue;
            } else {
                isReadonly = false;
                break;
            }
        }
        return isReadonly && (this.saveExampleWith as any)[propertyKey];
    }
    public cancelExamplePositonForm() {
        this.showExamplePositionForm = false;
    }
    public saveAsExamplePosition() {
        const requestData = {} as CostExamplePositionDataRequest;
        for (const [key, value] of Object.entries(this.propertiesForExamplePosition)) {
            if ((this.saveExampleWith as any)[key]) {
                Vue.set(requestData, key, value);
            }
        }
        this.$store
            .dispatch(this.storeExamplePositionAction, requestData)
            .then((res) => this.cancelExamplePositonForm());
    }

    public getInputLabel(key: string) {
        switch (key) {
            case 'name':
                return 'Nazwa';
            case 'unit':
                return 'Jednostka';
            case 'price':
                return 'Cena';
            case 'tax_rate':
                return 'Podatek';
        }
    }
    public getInputSuffix(key: string) {
        switch (key) {
            case 'price':
                return 'PLN';
            case 'tax_rate':
                return '%';
            default:
                return '';
        }
    }
}
