
import { Component, Inject, Watch } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';
import { costsModule } from '../../shared/state/module';
import { CostDataRequest } from '../../shared/requests/cost-data-request';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import { costPositionsListHeaders } from '../../shared/config';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import Position from '@/modules/costs/components/positions/position.vue';
import Draggable from 'vuedraggable';
import { logger } from '@/shared/services';
import { actionsTypes as settingsActionsTypes } from '@/modules/settings/shared/state';
import { parsePrice } from '@/shared/helpers';
import { investmentObjectsModule } from '@/modules/investments/shared/state/submodules';
import { costPositionsModule } from '../../shared/state/submodules';
import { validatePosition } from '@/modules/costs/shared/helpers/cost-positions';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: { FormActions, CardPicker, ListToolbar, Position, Draggable, TooltipBtn },
})
export default class AcceptanceFormEntryData extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    public actionsTypes = costPositionsModule.actionsTypes;
    public formRules = new FormRules();
    public itemData!: CostDataRequest;
    public store = this.$store.state.costsState.positionsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public investmentObjectsModule = investmentObjectsModule;
    public investmentObjectsFetchAction = investmentObjectsModule.actionsTypes.FETCH_DATA;
    public errorMessages: any = {};
    public showAddPositionForm = false;
    public parsePrice = parsePrice;
    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    };

    public investmentObjectsActionTypes = investmentObjectsModule.actionsTypes;

    get positions() {
        return this.store.data;
    }
    set positions(val: any) {
        this.store.data = val;
    }

    get headers() {
        const headers = costPositionsListHeaders;
        return [
            ...headers,
            { text: 'Cena netto', value: 'price' },
            { text: 'Kwota VAT', value: 'tax_total' },
            { text: 'Kwota brutto', value: 'total_price_tax_incl' },
            { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
        ];
    }

    get canNewItemBeCreated() {
        let canBe = true;
        for (const position of this.positions) {
            canBe = validatePosition(position);
            if (!canBe) {
                break;
            }
        }
        return canBe;
    }

    get valid() {
        return this.canNewItemBeCreated && this.positions.length > 0;
    }

    get summedPriceTaxExcl() {
        if (this.positions.length === 0) {
            return 0;
        }
        let counter = 0;
        this.positions.forEach((el: any) => {
            counter += this.priceTaxExcl(el);
        });
        return counter;
    }
    get summedPriceTaxTotal() {
        if (this.positions.length === 0) {
            return 0;
        }
        let counter = 0;
        this.positions.forEach((el: any) => {
            counter += this.taxTotal(el);
        });

        return counter;
    }

    get summedPriceTaxIncl() {
        if (this.positions.length === 0) {
            return 0;
        }
        let counter = 0;
        this.positions.forEach((el: any) => {
            counter += this.priceTaxExcl(el) + this.taxTotal(el);
        });

        return counter;
    }

    public setNewValues() {
        this.$store.state.costsState.current.total_price_tax_excl = this.summedPriceTaxExcl;
        this.$store.state.costsState.current.total_price = this.summedPriceTaxIncl;
    }

    public created() {
        this.fetchData();
        this.fetchUnits();
        this.fetchTaxRates();
    }

    public fetchData(
        cb = (value: any) => {
            /**/
        },
    ) {
        this.$store.dispatch(this.actionsTypes.FETCH_DATA, { simple: false, id: this.$route.params.id }).then((res) => {
            this.positions = this.positions.map((item: any, index: number) => {
                Vue.set(item, 'index', index + 1);
                return item;
            });
            cb(res);
        });
    }

    public fetchObjects(id: number) {
        this.$store.dispatch(this.investmentObjectsActionTypes.FETCH_DATA, {
            simple: true,
            filters: { investment_id: id },
        });
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`costs.positions.${action}`);
    }

    public createItem() {
        if (!this.positions) {
            this.positions = [];
        }
        const index: number = this.positions.length + 1;
        const newItem = {
            id: `${Math.random()}${new Date().toString}`,
            index,
            name: '',
            unit: '',
            quantity: 1,
            tax_rate: null,
            price: 1,
        } as any;
        this.positions.push(newItem as never);
    }

    public removePosition(e: any) {
        this.positions = this.positions.filter((el: any) => !this._.isEqual(el, e));
        this.positions.forEach((item: any, index: number) => {
            this.$set(item, 'index', index + 1);
        });
        this.$store.dispatch(this.removeAction, e.id).then(() => {
            this.positions = this.positions.filter((el: any) => !this._.isEqual(el, e));
            this.setNewValues();

        });
    }

    public onPositionDrop(e: any) {
        if (e.oldIndex === e.newIndex) {
            return;
        }
        this.positions = this.positions.map((item: any, index: number) => {
            Vue.set(item, 'index', index + 1);
            return item;
        });
    }

    public priceTaxExcl(item: any) {
        return item.quantity * item.price;
    }

    public taxTotal(item: any) {
        return item.quantity * (item.price * ((Number(item.tax_rate) || 0) / 100));
    }

    private fetchUnits() {
        this.$store.dispatch(settingsActionsTypes.FETCH_UNITS, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }

    private fetchTaxRates() {
        this.$store.dispatch(settingsActionsTypes.FETCH_TAX_RATES, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }
}
