
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import SelectMediaList from '@/modules/media/components/select.vue';
import { costAttachmentsTypes } from '@/shared/config/costs';
import { VModel, Watch } from 'vue-property-decorator';
import { FormRules } from '@/shared/validation/form-rules';
import { MediaFile } from '@/modules/media/shared/models/media-file';

type attachmentItem = MediaFile & {
    type: {
        id: string;
        name: string;
    };
};

@Component({
    components: {
        MediaFileInput,
        SelectMediaList,
    },
})
export default class AttachmentsSelector extends Vue {
    @VModel({ type: Array }) public items!: any[];
    public attachmentsList: attachmentItem[] = [];
    public mediaSelectDialog = false;
    public formRules = new FormRules();
    public valid = false;
    public costAttachmentsTypes = costAttachmentsTypes;
    public skipRequest = true;

    public mounted() {
        if (!this.items) { return; }
        this.skipRequest = true;

        this.attachmentsList = this.items.map((attachment) => {
            return {
                ...attachment.media_file,
                type: this.getAttachmentType(attachment.type) || costAttachmentsTypes[0],
            };
        });
    }

    public itemSelected(selectedMedia: MediaFile[]) {
        this.applySelection(selectedMedia);
        this.mediaSelectDialog = false;
    }

    private applySelection(selection: MediaFile[]) {
        const newAttachments = [];

        for (const file of selection) {
            const existingItem = this.attachmentsList.find((attachment: any) => attachment.media_file_id === file.id);
            newAttachments.push(existingItem || { ...file, type: costAttachmentsTypes[0] });
        }

        this.attachmentsList = newAttachments;
    }

    private getAttachmentType(attachmentItemId: string) {
        return this.costAttachmentsTypes.find((type) => type.id === attachmentItemId);
    }

    private parseToRequestData(arr: any[]) {
        return arr.map((el: attachmentItem) => {
            return {
                media_file_id: el.id,
                type: el.type.id,
            };
        });
    }

    @Watch('attachmentsList', { deep: true })
    private onAttachmentsChange(newVal: attachmentItem[]) {
        if (this.skipRequest) {
            this.skipRequest = false;
            return;
        }

        this.items = this.parseToRequestData(newVal);
    }
}
