
/**
 *  Dependencies list:
 *  - shared
 *  - module
 *  - icons
 */
import { Component } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import DocumentTemplateForm from '../../form/form.vue';

import ListView from './list-view.vue';
import TileView from './tile-view.vue';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import { mdiFormatListBulleted, mdiViewGrid } from '@mdi/js';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import { documentTemplatesListHeaders } from '@/modules/documents/shared/config';
import {
    documentCategoriesModule,
    documentTagsModule,
    documentTemplatesModule,
} from '@/modules/documents/shared/state/submodules';
import { DocumentTemplate } from '@/modules/documents/shared/models/document-template';
import { DocumentTemplateDataRequest } from '@/modules/documents/shared/requests/document-template-data-request';
import { logger } from '@/shared/services';
import { actionsTypes as adminActionTypes } from '@/modules/admin/shared/state';
import { documentTemplatesListFilter } from '@/modules/documents/shared/config/filters';
import MetaDataForm from '@/modules/documents/components/form/form-meta-data.vue';

@Component({
    components: {
        ItemsTable,
        DocumentTemplateForm,
        ListView,
        TileView,
        ListToolbar,
        FilterBar,
        MetaDataForm,
    },
})
export default class DocumentTemplatesList extends ListComponent<DocumentTemplate, DocumentTemplateDataRequest> {
    public headers: object[] = documentTemplatesListHeaders;
    public actionsTypes = documentTemplatesModule.actionsTypes;
    public mutationTypes = documentTemplatesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.documentsState.templatesState;
    public props: ModuleProps = documentTemplatesModule.moduleProps;
    public tileView = false;
    public icons = { mdiFormatListBulleted, mdiViewGrid };
    public filtersCopy: any = [];
    public filters = documentTemplatesListFilter;
    public metaDataDialog = false;

    public createTemplate() {
        this.setDefaultData({} as DocumentTemplateDataRequest);
        this.metaDataDialog = true;
    }

    public mounted() {
        this.fetchFiltersData(2, documentCategoriesModule.actionsTypes);
        this.fetchTagsFilters();
        this.setUsersFilters();
        this.fetchData();
    }

    public onFiltersChange(filters: any) {
        this.filtersCopy = filters;
        this.assignFilters(filters);
    }

    public fetchTagsFilters() {
        const index = 3;
        const tagsActionsTypes = documentTagsModule.actionsTypes;
        this.$store
            .dispatch(tagsActionsTypes.FETCH_DATA, { simple: true })
            .then((data) => {
                this.filters[index].data = data.data;
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    public async setUsersFilters() {
        const index = 1;
        let allUsers = await this.$store.dispatch(adminActionTypes.FETCH_USER_DATA, {
            simple: true,
            filters: { search: '' },
            page: 1,
        });
        allUsers = allUsers.data;
        allUsers = allUsers.map((user: any) => {
            if (user.id === this.$store.state.authState.user.id) {
                user.name += ' (Ja)';
            }
            return user;
        });

        this.filters[index].data = allUsers;
    }

    public fetchFiltersData(index: number, actionsTypes: { [k: string]: string }) {
        this.$store
            .dispatch(actionsTypes.FETCH_DATA, { simple: false })
            .then((res) => {
                this.filters[index].data = res.data.map((el: any) => {
                    return { name: el.id, label: el.name, value: false };
                });
            })
            .catch((error) => {
                logger.error(error);
            });
    }
}
