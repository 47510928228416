
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import EntryData from '@/modules/costs/components/form/enty-data.vue';
import { costsModule } from '../../shared/state/module';
import { CostDataRequest } from '../../shared/requests/cost-data-request';
import CostPositions from '@/modules/costs/components/form/cost-positions.vue';
import DocumentData from '@/modules/costs/components/form/document-data.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions, EntryData, CostPositions, DocumentData },
})
export default class CostForm extends FormComponent<CostDataRequest> {
    public actionsTypes = costsModule.actionsTypes;
    public store = this.$store.state.costsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new CostDataRequest();
    public createdCostId!: number;

    public currentStep = 1;
    public badRequest!: boolean;
    public isDone!: boolean;
    public goBack() {
        if (this.currentStep === 1) {
            this.$emit('canceled');
        } else {
            this.currentStep--;
        }
    }

    public getFormCompnent(step: number) {
        switch (step) {
            case 1:
                return 'EntryData';
            case 2:
                return 'DocumentData';
            case 3:
                return 'CostPositions';
        }
    }

    public created() {
        /**/
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj dokument kosztów' : 'Dodaj dokument kosztów';
    }

    public initForm() {
        this.form = this.$refs.createAcceptanceForm;
        this.initFocus();
         }

    @Watch('currentStep')
    public onCurrentStepChange(val: number) {
        if (val === 4) {
            this.submitForm(null, null);
        }
    }

    get newCost() {
        // tslint:disable-next-line:prefer-const
        let { positions, ...rest } = this.requestParams;
        (positions as any[]) = positions.map((position: any) => {
            if (position.investment_object_id === -1) {
                position.investment_object_id = null;
            }
            position.id = null;
            return position;
        });
        return { ...rest, positions };
    }

    public async submitForm(e: any, next: any) {
        this.$store
            .dispatch(this.storeAction, this.newCost)
            .then(({ data }: any) => {
                this.createdCostId = data.id;
            })
            .catch((err: any) => {
                this.badRequest = !!err;
            })
            .finally(() => {
                this.isDone = true;
                this.$forceUpdate();
            });
    }

    public initFocus() {
        this.badRequest = false;
        this.isDone = false;
        this.currentStep = 1;
        this.$emit('update:itemData', {
            positions: [
                {
                    id: `${Math.random()}${new Date().toString()}`,
                    index: 1,
                    name: '',
                    unit: '',
                    quantity: 1,
                    currency: 'PLN',
                    tax_rate: null,
                    price: 1,
                } as any,
            ] as any,
        } as CostDataRequest);
        this.requestData = new CostDataRequest();
    }
}
