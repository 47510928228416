
import { Component, Watch } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { costsListHeaders } from '@/modules/costs/shared/config';
import { costsModule } from '@/modules/costs/shared/state/module';
import CostForm from '@/modules/costs/components/form/form.vue';
import { costsListFilter } from '@/modules/costs/shared/config/filters';
import { Cost } from '../shared/models/cost';
import { CostDataRequest } from '../shared/requests/cost-data-request';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import { contractorsModule } from '@/modules/contractors/shared/state/module';
import { costTypesModule } from '../shared/state/submodules';
import { costCategoriesModule as categoriesModule } from '@/modules/costs/shared/state/submodules';
import { ListFilter } from '@/shared/types';
import { costStatuses } from '@/shared/config/costs';
import CostEditForm from '@/modules/costs/components/form/edit-form/form.vue';
import StatusPicker from '@/modules/costs/components/details/status-picker.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { callSuper } from '@/shared/helpers';
import NoInvestmentsBanner from '@/modules/investments/components/no-investments-banner.vue';

@Component({
    components: {
        StatusPicker,
        CostForm,
        ItemsTable,
        TextContrast,
        InvestmentTagChip,
        CostEditForm,
        TooltipBtn,
        NoInvestmentsBanner,
    },
})
export default class CostsList extends ListComponent<Cost, CostDataRequest> {
    public headers: object[] = costsListHeaders;
    public actionsTypes = costsModule.actionsTypes;
    public mutationTypes = costsModule.mutationsTypes;
    public props: ModuleProps = costsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.costsState;
    public loadingFilters = false;
    public filterConfig = costsListFilter;
    public containerDefaultMaxHeight!: any;
    public filters = [] as ListFilter[];
    public newCost = {
        positions: [
            {
                id: `${Math.random()}${new Date().toString()}`,
                index: 1,
                name: '',
                unit: '',
                quantity: 1,
                currency: 'PLN',
                tax_rate: null,
                price: 1,
            } as any,
        ] as any,
    } as Cost;

    public investmentsFetchAction = investmentsModule.actionsTypes.FETCH_DATA;
    public contractorsFetchAction = contractorsModule.actionsTypes.FETCH_DATA;
    public costTypesFetchAction = costTypesModule.actionsTypes.FETCH_DATA;
    public categoriesFetchAction = categoriesModule.actionsTypes.FETCH_DATA;

    get editFormDialog() {
        return this.$store.state.costsState.editFormDialog;
    }

    set editFormDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDIT_FORM_DIALOG, value);
    }

    public anyInvestmentExist = false;

    get toolbarProps() {
        if (this.anyInvestmentExist) {
            return undefined;
        }

        return {
            addButtonTooltip: 'Brak inwestycji',
            disabled: true,
        };
    }

    public onInvestmentExistenceCheck(exists: boolean) {
        this.anyInvestmentExist = exists;
    }

    public closeEditForm() {
        this.editFormDialog = false;
        this.error = '';
        this.errorMessages = {};
    }

    public editItem(item: Cost) {
        const editedItem: any = Object.assign({}, item as Cost);
        this.editedItem = editedItem as CostDataRequest;
        this.editFormDialog = true;
    }

    public itemSaved() {
        this.editFormDialog = false;
        callSuper(this, 'itemSaved');
    }

    public beforeMount() {
        this.formDialog = false;
    }

    public mounted() {
        this.containerDefaultMaxHeight = (this.$refs.container as HTMLElement).style.maxHeight;
    }

    public closeForm() {
        callSuper(this, 'closeForm');
        this.newCost = {
            positions: [
                {
                    id: `${Math.random()}${new Date().toString()}`,
                    index: 1,
                    name: '',
                    unit: '',
                    quantity: 1,
                    tax_rate: null,
                    price: 1,
                } as any,
            ] as any,
        } as Cost;
    }

    public async fetchFilters() {
        function convertToFilters(item: any) {
            return item.map((el: any) => {
                return {
                    label: el.name,
                    name: el.id,
                    value: false,
                };
            });
        }
        const { data: investments } = await this.$store.dispatch(this.investmentsFetchAction, { simple: true });
        const { data: contractors } = await this.$store.dispatch(this.contractorsFetchAction, { simple: true });
        const { data: costTypes } = await this.$store.dispatch(this.costTypesFetchAction, { simple: false });
        const { data: categories } = await this.$store.dispatch(this.categoriesFetchAction, { simple: false });
        this.filters = [
            { name: 'investment_id', label: 'Inwestycja', type: 'search', data: investments },
            { name: 'contractor_id', label: 'Kontrahent', type: 'search', data: contractors },
            { name: 'type_id', label: 'Rodzaj', type: 'search', data: costTypes },
            { name: 'categories', label: 'Kategorie', type: 'search', data: categories },
            { name: 'status', label: 'Status', type: 'select', data: convertToFilters(costStatuses) },
            ...this.filterConfig,
        ];
        this.loadingFilters = false;
    }

    public created() {
        this.loadingFilters = true;
        this.fetchFilters();
    }

    public statusType(statusType: string) {
        return costStatuses.find((obj) => obj.id === statusType);
    }

    public onStatusChange(item: Cost, e: any) {
        this.$store.dispatch(this.updateAction, { id: item.id, status: e }).then((res) => {
            if (res && res.data) {
                item.status = res.data.status;
            }
        });
    }
}
