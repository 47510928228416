
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { RentCostExampleIssuer } from '@/modules/rent-costs/shared/models/rent-cost-example-issuer';
import { RentCostExampleIssuerDataRequest } from '@/modules/rent-costs/shared/requests/rent-cost-example-issuer-data-request';
import {rentCostExampleIssuersListHeaders } from '@/modules/rent-costs/shared/config';
import { rentCostExampleIssuersModule } from '@/modules/rent-costs/shared/state/submodules';
import { ModuleProps } from '@/shared/state/template/module-props';
import ExampleIssuersForm from './form.vue';

@Component({
    components: {
        ItemsTable,
        ExampleIssuersForm,
    }
})
export default class ExampleIssuersList extends ListComponent<
    RentCostExampleIssuer,
    RentCostExampleIssuerDataRequest> {
        public headers: object[] = rentCostExampleIssuersListHeaders;
        public actionsTypes = rentCostExampleIssuersModule.actionsTypes;
        public mutationTypes = rentCostExampleIssuersModule.mutationsTypes;
        public props: ModuleProps = rentCostExampleIssuersModule.moduleProps;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public store = this.$store.state.rentcostsState.exampleIssuersState;

    }


